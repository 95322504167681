import { formatNumber } from "@angular/common";
import { Canvas, Cell, ICustomTableLayout, IImg, Img, IStyleDefinition, ISVG, Line, PdfMakeWrapper, Rect, SVG, Table, Txt } from "pdfmake-wrapper";
import { PdfViewerPage } from "src/app/main/components/pdf-viewer/pdf-viewer";
import { pdfColors } from "../../../game-module/components/pdf-report/pdf-colors";
import { GkLastGameModel, IndividualLastGameModel } from "../../dashboard.model";

import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { handballField, logoWithTextSVG, svgTextLogo, teamLogoSVG } from "../../../game-module/components/pdf-report/pdf-svg-in-string-format";
import { RectConfig, toUpperCase } from "../../../game-module/components/pdf-report/pdf-report.component";
import { IndividualPlayerPdfInfo } from "src/app/main/components/dash-individual-stats/dash-individual-stats.component";
import { decimalToFixed, getGameSystemColor, globalDataDate, teamLogoResolver } from "../../../game-module/components/pdf-report/pdf-report-helpers";
import { CoreService } from "src/app/shared-services/core.service";


export interface ExtraPlayerData {
  individualPlayerPdfInfo: IndividualPlayerPdfInfo,

  pageTitle?: string;
  playerImageURL?: string;
  teamName?: string;
}

const toFixedNum = (n: number, deceimals: number) => n.toFixed(deceimals).replace(/\.?0*$/, '');
const pdfCreationDate = globalDataDate(new Date().toDateString());

const layoutTableBodyGrid: ICustomTableLayout = {
  hLineWidth: (i: any, node: any) => {
    if (i === 0 || i === node.table.body.length) {
      return 0;
    }
    return 2;
  },
  vLineWidth: (i: any, node: any) => {
    return (i === 0 || i === node.table.widths.length) ? 0 : 2;
  },
  hLineColor: function (i: any, node: any) {
    // return (i === 0 || i === node.table.body.length) ? 'red' : 'blue';
    return (i === 0 || i === node.table.body.length) ? 'red' : '#F1F5F8';
  },
  vLineColor: function (i: any, node: any) {
    // return (i === 0 || i === node.table.body.length) ? 'red' : 'blue';
    return (i === 0 || i === node.table.widths.length) ? 'red' : '#F1F5F8';
  },
  paddingTop: function (i: any, node: any) {
    return 1
  },
  paddingBottom: function (i: any, node: any) {
    return 1;
  }
};

const layoutTableBodyGridTest: ICustomTableLayout = {
  hLineWidth: (i: any, node: any) => {
    if (i === 0 || i === node.table.body.length) {
      return 0;
    }
    return 2;
  },
  vLineWidth: (i: any, node: any) => {
    return (i === 0 || i === node.table.widths.length) ? 0 : 2;
  },
  hLineColor: function (i: any, node: any) {
    return (i === 0 || i === node.table.body.length) ? 'red' : '#FFFFFF';
  },
  vLineColor: function (i: any, node: any) {
    return (i === 0 || i === node.table.body.length) ? 'red' : '#FFFFFF';
  },

  paddingTop: function (i: any, node: any) {
    return 1
  },
  paddingBottom: function (i: any, node: any) {
    return 1;
  }
};

export const layoutTableBodyGridTest2: ICustomTableLayout = {
  hLineWidth: (i: any, node: any) => {
    if (i === 0 || i === node.table.body.length) {
      return 0;
    }
    return 2;
  },
  vLineWidth: (i: any, node: any) => {
    return (i === 0 || i === node.table.widths.length) ? 0 : 2;
  },
  hLineColor: function (i: any, node: any) {
    return (i === 0 || i === node.table.body.length) ? 'red' : '#FFF';

  },
  vLineColor: function (i: any, node: any) {
    return (i === 0 || i === node.table.widths.length) ? 'red' : '#FFF';
  }
};

const layoutTableIndividualA: ICustomTableLayout = {
  hLineWidth: (rowIndex?: number, node?: any, columnIndex?: number) => {
    if (
      node.table.body[rowIndex - 1]
      &&
      [
        'Assists',
        'Posession Events'
      ].includes(node.table.body[rowIndex - 1][0]['text'])
    ) {
      return 2
    }

    if (
      node.table.body[rowIndex]
      &&
      [
        'Positional Offense %',
        'Superiority %'
      ].includes(node.table.body[rowIndex][0]['text'])
    ) {
      return 2
    }
    return 0;
  },
  vLineWidth: (i: any, node: any) => {
    return (i === 0 || i === node.table.widths.length) ? 0 : 2;
  },
  hLineColor: function (i: any, node: any) {
    return (i === 0 || i === node.table.body.length) ? 'red' : '#F1F5F8';
  },
  vLineColor: function (i: any, node: any) {
    return (i === 0 || i === node.table.widths.length) ? 'red' : '#F1F5F8';
  },

  paddingTop: function (i: any, node: any) {
    return 1
  },
  paddingBottom: function (i: any, node: any) {
    return 1;
  }
};


const PDF_URL = 'www.handball.ai';
let translateServices;
let selectedPlayerRol = '';
let extraPlayerData: ExtraPlayerData;
let firstName = '';
let lastName = '';
let playerPosition = '';

let selectedGames;
let gameSystemTbl;
let goalkeeperReport;

export function createPdf(printOptions, loadingCtrl, translateService, selectedPlayerPosition, datePipe, entityFilterDtoMap, selectedPlayer, modalCtrl, core: CoreService, extraData?: ExtraPlayerData) {
  selectedGames = printOptions['selectedGames'];

  firstName = entityFilterDtoMap.get(selectedPlayer)?.name.split(' ')[0].trim();
  lastName = entityFilterDtoMap.get(selectedPlayer)?.name.split(' ')[1]?.trim() ?? '';
  playerPosition = entityFilterDtoMap.get(selectedPlayer)?.position;

  translateServices = translateService
  selectedPlayerRol = selectedPlayerPosition
  extraPlayerData = { ...extraData }

  gameSystemTbl = printOptions['gameSystemTbl'];

  goalkeeperReport = printOptions['goalkeeperReport'];

 // TODO log delete after presentation
 console.log('%c printOptions gameSystemTbl', 'color: yellow',  printOptions['gameSystemTbl']);

  loadingCtrl.create({ message: core.trans.instant('Generating PDF. Please wait') }).then(async loading => {
    loading.present();
    const pdf = new PdfMakeWrapper();

    const indPlshooting = document.querySelectorAll("#ind-plshooting svg");
    const indPlshootingGk = document.querySelectorAll("#ind-plshooting-gk svg");
    // const indDoughnut1: HTMLCanvasElement = document.querySelector("#ind-doughnut1 canvas");
    // const indDoughnut2: HTMLCanvasElement = document.querySelector("#ind-doughnut2 canvas");

    const indDoughnut1_PDF: HTMLCanvasElement = document.querySelector("#ind-doughnut1_pdf canvas");
    const indDoughnut2_PDF: HTMLCanvasElement = document.querySelector("#ind-doughnut2_pdf canvas");

    const individualAggregatedTbl3 = printOptions["individualAggregatedTbl3"];
    const individualAggregatedTbl4 = printOptions["individualAggregatedTbl4"];
    const lastGames = printOptions["lastGames"];
    const gkLastGames = printOptions["gkLastGames"];
    const quickStats = printOptions["quickStats"];

    const alternatingColorTableLayout: ICustomTableLayout = {
      fillColor: function (rowIndex) {
        return (rowIndex % 2 === 0 && rowIndex != 0) ? '#f0f0f0' : 'white';
      },
      hLineWidth: function (rowIndex) {
        return (rowIndex === 1) ? 1 : 0;
      },
      hLineColor: function (rowIndex) {
        return (rowIndex === 1) ? '#f0f0f0' : 'white';
      },
      vLineWidth: function (rowIndex) {
        return 0;
      },
    };

    const quickStatsLines = [1, 4, 8, 14, 20, 26];
    const quickStatsTableLayout: ICustomTableLayout = {
      hLineWidth: function (rowIndex) {
        return (quickStatsLines.includes(rowIndex)) ? 1 : 0;
      },
      hLineColor: function (rowIndex) {
        return (quickStatsLines.includes(rowIndex)) ? '#0d385f' : 'white';
      },
      vLineWidth: function (rowIndex) {
        return 0;
      },
    };

    const alternatingColorTableHeaderStyle: IStyleDefinition = {
      fontSize: 8,
      bold: true,
      color: "#0d385f"
    }

    const alternatingColorTableContentStyle: IStyleDefinition = {
      fontSize: 8,
      bold: false,
      color: "black",
      noWrap: true
    };

    pdf.styles({
      alternatingColorTableHeaderStyle, alternatingColorTableContentStyle,

      firstPageTeamCell: {
        fontSize: 20,
        color: '#1A375C',
        alignment: 'left',
        bold: true,
      },
      firstPageGlobalDate: {
        fontSize: 17,
        color: '#1A375C',
        alignment: 'center',
        bold: true,
      },

      headerPlayerCell: {
        fontSize: 8,
        color: '#1A375C',
        alignment: 'left',
        bold: true,
      },
      headerPlayerCellSmall: {
        fontSize: 7
      },

      header__cell__individual_a: {
        fontSize: 9,
        color: '#1A375C',
        alignment: 'left',
        bold: true,
      },
      table__cell__individual_a: {
        fontSize: 8,
        color: '#1A375C',
        alignment: 'center',
        bold: false,
      },

      header__cell__individual_b: {
        fontSize: 9,
        color: '#1A375C',
        alignment: 'left',
        bold: true,
      },
      table__cell__individual_b: {
        fontSize: 8,
        color: '#1A375C',
        alignment: 'center',
        bold: false,
      },

      header__cell__individual_c: {
        fontSize: 6,
        color: '#1A375C',
        alignment: 'left',
        bold: true,
      },
      table__cell__individual_c: {
        fontSize: 8,
        color: '#1A375C',
        alignment: 'center',
        bold: false,
      },

      header__cell__individual_f: {
        fontSize: 7,
        color: '#1A375C',
        alignment: 'left',
        bold: true,
      },
      table__cell__individual_f: {
        fontSize: 8,
        color: '#1A375C',
        alignment: 'center',
        bold: false,
      },

      first_page__general_info_cell: {
        fontSize: 13,
        color: '#1A375C',
        alignment: 'left',
        bold: true,
      },
      goals_failed_shorts_chart: {
        fontSize: 8,
        color: '#1A375C',
        alignment: 'center',
        bold: true,

      },
      goals_per_phase_chart: {
        fontSize: 8,
        color: '#1A375C',
        alignment: 'center',
        bold: true,
      },

      goals_failed_shorts_chart_legend: {
        fontSize: 7,
        color: '#1A375C',
        alignment: 'center',
        bold: true,
      }
    });

    let individualAggregatedTbl3TableBody = [];
    individualAggregatedTbl3TableBody.push([
      new Txt("").end,
      new Txt(translateService.instant("Total")).style("alternatingColorTableHeaderStyle").end,
      new Txt(translateService.instant("Average")).style("alternatingColorTableHeaderStyle").end
    ]);
    individualAggregatedTbl3.forEach(element => {
      individualAggregatedTbl3TableBody.push([
        new Txt(element.template).style("alternatingColorTableContentStyle").end,
        new Txt(element.total).style("alternatingColorTableContentStyle").end,
        new Txt(element.average).style("alternatingColorTableContentStyle").end
      ])
    });
    const individualAggregatedTbl3Table = new Table(individualAggregatedTbl3TableBody).headerRows(1).layout(alternatingColorTableLayout);

    let individualAggregatedTbl4TableBody = [];
    individualAggregatedTbl4TableBody.push([
      new Txt("").end,
      new Txt(translateService.instant("Total")).style("alternatingColorTableHeaderStyle").end,
      new Txt(translateService.instant("Average")).style("alternatingColorTableHeaderStyle").end
    ]);
    individualAggregatedTbl4.forEach(element => {
      individualAggregatedTbl4TableBody.push([
        new Txt(element.template).style("alternatingColorTableContentStyle").end,
        new Txt(element.total).style("alternatingColorTableContentStyle").end,
        new Txt(element.average).style("alternatingColorTableContentStyle").end
      ])
    });
    const individualAggregatedTbl4Table = new Table(individualAggregatedTbl4TableBody).headerRows(1).layout(alternatingColorTableLayout);

    const individualTable = new Table([
      [
        individualAggregatedTbl3Table.end,
        individualAggregatedTbl4Table.end
      ]
    ]).layout('noBorders');

    let lastGamesTableBody = [];
    if (selectedPlayerPosition === 'gk') {
      lastGamesTableBody.push([
        new Txt(translateService.instant("Game Data")).style("alternatingColorTableHeaderStyle").end,
        new Txt(translateService.instant("Teams Name")).style("alternatingColorTableHeaderStyle").end,
        new Txt(translateService.instant("Time Played")).style("alternatingColorTableHeaderStyle").end,
        new Txt(translateService.instant("Played Poses")).style("alternatingColorTableHeaderStyle").end,
        new Txt(translateService.instant("Possession Events")).style("alternatingColorTableHeaderStyle").end,
        new Txt(translateService.instant("Goals")).style("alternatingColorTableHeaderStyle").end,
        new Txt(translateService.instant("Saves")).style("alternatingColorTableHeaderStyle").end,
        new Txt(translateService.instant("Lost Balls")).style("alternatingColorTableHeaderStyle").end,
        new Txt(translateService.instant("Save Eff")).style("alternatingColorTableHeaderStyle").end,
        new Txt(translateService.instant("Player Score")).style("alternatingColorTableHeaderStyle").end,

        new Txt(translateService.instant("Gk Saves 7 Meters")).style("alternatingColorTableHeaderStyle").end,
        new Txt(translateService.instant("Gk Received Goals 7 Meters")).style("alternatingColorTableHeaderStyle").end
      ]);
      gkLastGames.forEach((element: GkLastGameModel) => {
        lastGamesTableBody.push([
          new Txt(element.gameDate !== 'Total' ? datePipe.transform(element.gameDate, "mediumDate") : element.gameDate).style("alternatingColorTableContentStyle").end,
          new Txt(element.teamsName).style("alternatingColorTableContentStyle").end,
          new Txt(element.timePlayed || "").style("alternatingColorTableContentStyle").end,
          new Txt(element.playedPoses?.toString() || "").style("alternatingColorTableContentStyle").end,
          new Txt(element.possessionEvents.toString()).style("alternatingColorTableContentStyle").end,
          new Txt(element.goals.toString()).style("alternatingColorTableContentStyle").end,
          new Txt(element.saves.toString()).style("alternatingColorTableContentStyle").end,
          new Txt(element.lostBalls.toString()).style("alternatingColorTableContentStyle").end,
          new Txt(element.saveEff).style("alternatingColorTableContentStyle").end,
          new Txt(formatNumber(element.playerScore, "en-US", "1.0-1")).style("alternatingColorTableContentStyle").end,

          new Txt(element.gkSaves7Meters?.toString()).style("alternatingColorTableContentStyle").end,
          new Txt(element.gkReceivedGoals7Meters?.toString()).style("alternatingColorTableContentStyle").end
        ]);
      });
    } else {
      lastGamesTableBody.push([
        new Txt(translateService.instant("Game Date")).style("alternatingColorTableHeaderStyle").end,
        new Txt(translateService.instant("Teams Name")).style("alternatingColorTableHeaderStyle").end,
        new Txt(translateService.instant("Time Played")).style("alternatingColorTableHeaderStyle").end,
        new Txt(translateService.instant("Played Poses")).style("alternatingColorTableHeaderStyle").end,
        new Txt(translateService.instant("Possession Events")).style("alternatingColorTableHeaderStyle").end,
        new Txt(translateService.instant("Goals")).style("alternatingColorTableHeaderStyle").end,
        new Txt(translateService.instant("Assists")).style("alternatingColorTableHeaderStyle").end,
        new Txt(translateService.instant("Failed Shots")).style("alternatingColorTableHeaderStyle").end,
        new Txt(translateService.instant("Post Outs")).style("alternatingColorTableHeaderStyle").end,
        new Txt(translateService.instant("Lost Balls")).style("alternatingColorTableHeaderStyle").end,
        new Txt(translateService.instant("Lost Balls Perc")).style("alternatingColorTableHeaderStyle").end,
        new Txt(translateService.instant("Eff")).style("alternatingColorTableHeaderStyle").end,
        new Txt(translateService.instant("Shoot Eff")).style("alternatingColorTableHeaderStyle").end,
        new Txt(translateService.instant("Player Score")).style("alternatingColorTableHeaderStyle").end
      ]);
      lastGames.forEach((element: IndividualLastGameModel) => {
        lastGamesTableBody.push([
          new Txt(element.gameDate !== 'Total' ? datePipe.transform(element.gameDate, "mediumDate") : element.gameDate).style("alternatingColorTableContentStyle").end,
          new Txt(element.teamsName).style("alternatingColorTableContentStyle").end,
          new Txt(element.timePlayed || "").style("alternatingColorTableContentStyle").end,
          new Txt(element.playedPoses?.toString() || "").style("alternatingColorTableContentStyle").end,
          new Txt(element.possessionEvents.toString()).style("alternatingColorTableContentStyle").end,
          new Txt(element.goals.toString()).style("alternatingColorTableContentStyle").end,
          new Txt(element.assists.toString()).style("alternatingColorTableContentStyle").end,
          new Txt(element.failedShots.toString()).style("alternatingColorTableContentStyle").end,
          new Txt(element.postOuts.toString()).style("alternatingColorTableContentStyle").end,
          new Txt(element.lostBalls.toString()).style("alternatingColorTableContentStyle").end,
          new Txt(element.lostBallsPerc).style("alternatingColorTableContentStyle").end,
          new Txt(element.eff).style("alternatingColorTableContentStyle").end,
          new Txt(element.shootEff).style("alternatingColorTableContentStyle").end,
          new Txt(formatNumber(element.playerScore, "en-US", "1.0-1")).style("alternatingColorTableContentStyle").end,
        ])
      });
    }

    // const lastGamesTable = new Table(lastGamesTableBody).headerRows(1).layout(alternatingColorTableLayout);
    const quickStatsTableBody = [];
    //line
    if (quickStats.totalQuickStats.totalPlayedTime) {
      quickStatsTableBody.push([
        new Txt(translateService.instant("TIME")).style("alternatingColorTableHeaderStyle").end,
        new Txt(quickStats.totalQuickStats.totalPlayedTime).style("alternatingColorTableContentStyle").end,
        new Txt(quickStats.avgQuickStats.totalPlayedTime).style("alternatingColorTableContentStyle").end
      ]);
    }

    if (quickStats.totalQuickStats.totalPossessions) {
      quickStatsTableBody.push([
        new Txt(translateService.instant("POSESSIONS")).style("alternatingColorTableHeaderStyle").end,
        new Txt(toFixedNum(quickStats.totalQuickStats.totalPossessions, 2)).style("alternatingColorTableContentStyle").end,
        new Txt(toFixedNum(quickStats.avgQuickStats.totalPossessions, 2)).style("alternatingColorTableContentStyle").end
      ]);
    }

    quickStatsTableBody.push([
      new Txt(translateService.instant("POSESSION EVENTS")).style("alternatingColorTableHeaderStyle").end,
      new Txt(toFixedNum(quickStats.totalQuickStats.totalPossessionEvents, 2)).style("alternatingColorTableContentStyle").end,
      new Txt(toFixedNum(quickStats.avgQuickStats.totalPossessionEvents, 2)).style("alternatingColorTableContentStyle").end
    ]);

    //line
    quickStatsTableBody.push([
      new Txt(translateService.instant("GOALS")).style("alternatingColorTableHeaderStyle").end,
      new Txt(toFixedNum(quickStats.totalQuickStats.totalGoals, 2)).style("alternatingColorTableContentStyle").end,
      new Txt(toFixedNum(quickStats.avgQuickStats.totalGoals, 2)).style("alternatingColorTableContentStyle").end
    ]);
    quickStatsTableBody.push([
      new Txt(translateService.instant("FAILED SHOTS + POSTS/OUT")).style("alternatingColorTableHeaderStyle").end,
      new Txt(toFixedNum(quickStats.totalQuickStats.totalFailedShots, 2)).style("alternatingColorTableContentStyle").end,
      new Txt(toFixedNum(quickStats.avgQuickStats.totalFailedShots, 2)).style("alternatingColorTableContentStyle").end
    ]);

    quickStatsTableBody.push([
      new Txt(translateService.instant("POSTS/OUT")).style("alternatingColorTableHeaderStyle").end,
      new Txt(toFixedNum(quickStats.totalQuickStats.totalPostOuts, 2)).style("alternatingColorTableContentStyle").end,
      new Txt(toFixedNum(quickStats.avgQuickStats.totalPostOuts, 2)).style("alternatingColorTableContentStyle").end
    ]);

    quickStatsTableBody.push([
      new Txt(translateService.instant("ASSISTS")).style("alternatingColorTableHeaderStyle").end,
      new Txt(toFixedNum(quickStats.totalQuickStats.totalAssist, 2)).style("alternatingColorTableContentStyle").end,
      new Txt(toFixedNum(quickStats.avgQuickStats.totalAssist, 2)).style("alternatingColorTableContentStyle").end
    ]);

    //line
    quickStatsTableBody.push([
      new Txt(translateService.instant("EFF %")).style("alternatingColorTableHeaderStyle").end,
      new Txt(
        formatNumber(quickStats.totalQuickStats.totalGoals, "en-US", "1.0-1")
        + " / " +
        formatNumber(quickStats.totalQuickStats.totalPossessionEvents, "en-US", "1.0-1")
        + " (" + calculatePercentageItem(quickStats.totalQuickStats.totalGoals, quickStats.totalQuickStats.totalPossessionEvents) + " %)").style("alternatingColorTableContentStyle").end,
      new Txt(
        formatNumber(quickStats.avgQuickStats.totalGoals, "en-US", "1.0-1")
        + " / " +
        formatNumber(quickStats.avgQuickStats.totalPossessionEvents, "en-US", "1.0-1")
        + " (" + calculatePercentageItem(quickStats.avgQuickStats.totalGoals, quickStats.avgQuickStats.totalPossessionEvents) + " %)").style("alternatingColorTableContentStyle").end
    ]);
    quickStatsTableBody.push([
      new Txt("").end,
      new Canvas([
        new Line([0, 0], [50, 0]).lineWidth(2).end,
        new Line([0, 0], [((quickStats.totalQuickStats.totalGoals / quickStats.totalQuickStats.totalPossessionEvents) * 50) || 0, 0]).lineColor("#2bc253").lineWidth(2).end
      ]).end,
      new Canvas([
        new Line([0, 0], [50, 0]).lineWidth(2).end,
        new Line([0, 0], [((quickStats.avgQuickStats.totalGoals / quickStats.avgQuickStats.totalPossessionEvents) * 50) || 0, 0]).lineColor("#2bc253").lineWidth(2).end
      ]).end
    ]);

    quickStatsTableBody.push([
      new Txt(translateService.instant("SHOOTING EFF %'")).style("alternatingColorTableHeaderStyle").end,
      new Txt(
        formatNumber(quickStats.totalQuickStats.totalGoals, "en-US", "1.0-1")
        + " / " +
        formatNumber((quickStats.totalQuickStats.totalFailedShots + quickStats.totalQuickStats.totalGoals), "en-US", "1.0-1")
        + " (" + calculatePercentageItem(quickStats.totalQuickStats.totalGoals, (quickStats.totalQuickStats.totalFailedShots + quickStats.totalQuickStats.totalGoals)) + " %)").style("alternatingColorTableContentStyle").end,
      new Txt(
        formatNumber(quickStats.avgQuickStats.totalGoals, "en-US", "1.0-1")
        + " / " +
        formatNumber((quickStats.avgQuickStats.totalFailedShots + quickStats.avgQuickStats.totalGoals), "en-US", "1.0-1")
        + " (" + calculatePercentageItem(quickStats.avgQuickStats.totalGoals, (quickStats.avgQuickStats.totalFailedShots + quickStats.avgQuickStats.totalGoals)) + " %)").style("alternatingColorTableContentStyle").end
    ]);
    quickStatsTableBody.push([
      new Txt("").end,
      new Canvas([
        new Line([0, 0], [50, 0]).lineWidth(2).end,
        new Line([0, 0], [((quickStats.totalQuickStats.totalGoals / (quickStats.totalQuickStats.totalFailedShots + quickStats.totalQuickStats.totalGoals)) * 50) || 0, 0]).lineColor("#2bc253").lineWidth(2).end
      ]).end,
      new Canvas([
        new Line([0, 0], [50, 0]).lineWidth(2).end,
        new Line([0, 0], [((quickStats.avgQuickStats.totalGoals / (quickStats.avgQuickStats.totalFailedShots + quickStats.avgQuickStats.totalGoals)) * 50) || 0, 0]).lineColor("#2bc253").lineWidth(2).end
      ]).end
    ]);

    quickStatsTableBody.push([
      new Txt(translateService.instant("LOST BALLS %")).style("alternatingColorTableHeaderStyle").end,
      new Txt(
        formatNumber(quickStats.totalQuickStats.totalLostBalls, "en-US", "1.0-1")
        + " / " +
        formatNumber(quickStats.totalQuickStats.totalPossessionEvents, "en-US", "1.0-1")
        + " (" + calculatePercentageItem(quickStats.totalQuickStats.totalLostBalls, quickStats.totalQuickStats.totalPossessionEvents) + " %)").style("alternatingColorTableContentStyle").end,
      new Txt(
        formatNumber(quickStats.avgQuickStats.totalLostBalls, "en-US", "1.0-1")
        + " / " +
        formatNumber(quickStats.avgQuickStats.totalPossessionEvents, "en-US", "1.0-1")
        + " (" + calculatePercentageItem(quickStats.avgQuickStats.totalLostBalls, quickStats.avgQuickStats.totalPossessionEvents) + " %)").style("alternatingColorTableContentStyle").end
    ]);
    quickStatsTableBody.push([
      new Txt("").end,
      new Canvas([
        new Line([0, 0], [50, 0]).lineWidth(2).end,
        new Line([0, 0], [((quickStats.totalQuickStats.totalLostBalls / quickStats.totalQuickStats.totalPossessionEvents) * 50) || 0, 0]).lineColor("#2bc253").lineWidth(2).end
      ]).end,
      new Canvas([
        new Line([0, 0], [50, 0]).lineWidth(2).end,
        new Line([0, 0], [((quickStats.avgQuickStats.totalLostBalls / quickStats.avgQuickStats.totalPossessionEvents) * 50) || 0, 0]).lineColor("#2bc253").lineWidth(2).end
      ]).end
    ]);

    //line
    quickStatsTableBody.push([
      new Txt(translateService.instant("POSITIONAL OFFENSE %")).style("alternatingColorTableHeaderStyle").end,
      new Txt(
        formatNumber(quickStats.totalQuickStats.offensePositionalGoals, "en-US", "1.0-1")
        + " / " +
        formatNumber(quickStats.totalQuickStats.offensePositionalPossession, "en-US", "1.0-1")
        + " (" + calculatePercentageItem(quickStats.totalQuickStats.offensePositionalGoals, quickStats.totalQuickStats.offensePositionalPossession) + " %)").style("alternatingColorTableContentStyle").end,
      new Txt(
        formatNumber(quickStats.avgQuickStats.offensePositionalGoals, "en-US", "1.0-1")
        + " / " +
        formatNumber(quickStats.avgQuickStats.offensePositionalPossession, "en-US", "1.0-1")
        + " (" + calculatePercentageItem(quickStats.avgQuickStats.offensePositionalGoals, quickStats.avgQuickStats.offensePositionalPossession) + " %)").style("alternatingColorTableContentStyle").end
    ]);
    quickStatsTableBody.push([
      new Txt("").end,
      new Canvas([
        new Line([0, 0], [50, 0]).lineWidth(2).end,
        new Line([0, 0], [((quickStats.totalQuickStats.offensePositionalGoals / quickStats.totalQuickStats.offensePositionalPossession) * 50) || 0, 0]).lineColor("#2bc253").lineWidth(2).end
      ]).end,
      new Canvas([
        new Line([0, 0], [50, 0]).lineWidth(2).end,
        new Line([0, 0], [((quickStats.avgQuickStats.offensePositionalGoals / quickStats.avgQuickStats.offensePositionalPossession) * 50) || 0, 0]).lineColor("#2bc253").lineWidth(2).end
      ]).end
    ]);

    quickStatsTableBody.push([
      new Txt(translateService.instant("FASTBREAK %")).style("alternatingColorTableHeaderStyle").end,
      new Txt(
        formatNumber(quickStats.totalQuickStats.fastBreakGoals, "en-US", "1.0-1")
        + " / " +
        formatNumber(quickStats.totalQuickStats.fastBreakPossession, "en-US", "1.0-1")
        + " (" + calculatePercentageItem(quickStats.totalQuickStats.fastBreakGoals, quickStats.totalQuickStats.fastBreakPossession) + " %)").style("alternatingColorTableContentStyle").end,
      new Txt(
        formatNumber(quickStats.avgQuickStats.fastBreakGoals, "en-US", "1.0-1")
        + " / " +
        formatNumber(quickStats.avgQuickStats.fastBreakPossession, "en-US", "1.0-1")
        + " (" + calculatePercentageItem(quickStats.avgQuickStats.fastBreakGoals, quickStats.avgQuickStats.fastBreakPossession) + " %)").style("alternatingColorTableContentStyle").end
    ]);
    quickStatsTableBody.push([
      new Txt("").end,
      new Canvas([
        new Line([0, 0], [50, 0]).lineWidth(2).end,
        new Line([0, 0], [((quickStats.totalQuickStats.fastBreakGoals / quickStats.totalQuickStats.fastBreakPossession) * 50) || 0, 0]).lineColor("#2bc253").lineWidth(2).end
      ]).end,
      new Canvas([
        new Line([0, 0], [50, 0]).lineWidth(2).end,
        new Line([0, 0], [((quickStats.avgQuickStats.fastBreakGoals / quickStats.avgQuickStats.fastBreakPossession) * 50) || 0, 0]).lineColor("#2bc253").lineWidth(2).end
      ]).end
    ]);

    quickStatsTableBody.push([
      new Txt(translateService.instant("COUNTER GOAL %")).style("alternatingColorTableHeaderStyle").end,
      new Txt(
        formatNumber(quickStats.totalQuickStats.counterGoalGoals, "en-US", "1.0-1")
        + " / " +
        formatNumber(quickStats.totalQuickStats.counterGoalPossession, "en-US", "1.0-1")
        + " (" + calculatePercentageItem(quickStats.totalQuickStats.counterGoalGoals, quickStats.totalQuickStats.counterGoalPossession) + " %)").style("alternatingColorTableContentStyle").end,
      new Txt(
        formatNumber(quickStats.avgQuickStats.counterGoalGoals, "en-US", "1.0-1")
        + " / " +
        formatNumber(quickStats.avgQuickStats.counterGoalPossession, "en-US", "1.0-1")
        + " (" + calculatePercentageItem(quickStats.avgQuickStats.counterGoalGoals, quickStats.avgQuickStats.counterGoalPossession) + " %)").style("alternatingColorTableContentStyle").end
    ]);
    quickStatsTableBody.push([
      new Txt("").end,
      new Canvas([
        new Line([0, 0], [50, 0]).lineWidth(2).end,
        new Line([0, 0], [((quickStats.totalQuickStats.counterGoalGoals / quickStats.totalQuickStats.counterGoalPossession) * 50) || 0, 0]).lineColor("#2bc253").lineWidth(2).end
      ]).end,
      new Canvas([
        new Line([0, 0], [50, 0]).lineWidth(2).end,
        new Line([0, 0], [((quickStats.avgQuickStats.counterGoalGoals / quickStats.avgQuickStats.counterGoalPossession) * 50) || 0, 0]).lineColor("#2bc253").lineWidth(2).end
      ]).end
    ]);

    //line
    quickStatsTableBody.push([
      new Txt(translateService.instant("SUPERIORITY %")).style("alternatingColorTableHeaderStyle").end,
      new Txt(
        formatNumber(quickStats.totalQuickStats.superiorityGoals, "en-US", "1.0-1")
        + " / " +
        formatNumber(quickStats.totalQuickStats.superiorityPossessions, "en-US", "1.0-1")
        + " (" + calculatePercentageItem(quickStats.totalQuickStats.superiorityGoals, quickStats.totalQuickStats.superiorityPossessions) + " %)").style("alternatingColorTableContentStyle").end,
      new Txt(
        formatNumber(quickStats.avgQuickStats.superiorityGoals, "en-US", "1.0-1")
        + " / " +
        formatNumber(quickStats.avgQuickStats.superiorityPossessions, "en-US", "1.0-1")
        + " (" + calculatePercentageItem(quickStats.avgQuickStats.superiorityGoals, quickStats.avgQuickStats.superiorityPossessions) + " %)").style("alternatingColorTableContentStyle").end
    ]);
    quickStatsTableBody.push([
      new Txt("").end,
      new Canvas([
        new Line([0, 0], [50, 0]).lineWidth(2).end,
        new Line([0, 0], [((quickStats.totalQuickStats.superiorityGoals / quickStats.totalQuickStats.superiorityPossessions) * 50) || 0, 0]).lineColor("#2bc253").lineWidth(2).end
      ]).end,
      new Canvas([
        new Line([0, 0], [50, 0]).lineWidth(2).end,
        new Line([0, 0], [((quickStats.avgQuickStats.superiorityGoals / quickStats.avgQuickStats.superiorityPossessions) * 50) || 0, 0]).lineColor("#2bc253").lineWidth(2).end
      ]).end
    ]);

    quickStatsTableBody.push([
      new Txt(translateService.instant("EQUALITY %")).style("alternatingColorTableHeaderStyle").end,
      new Txt(
        formatNumber(quickStats.totalQuickStats.equalityGoals, "en-US", "1.0-1")
        + " / " +
        formatNumber(quickStats.totalQuickStats.equalityPossessions, "en-US", "1.0-1")
        + " (" + calculatePercentageItem(quickStats.totalQuickStats.equalityGoals, quickStats.totalQuickStats.equalityPossessions) + " %)").style("alternatingColorTableContentStyle").end,
      new Txt(
        formatNumber(quickStats.avgQuickStats.equalityGoals, "en-US", "1.0-1")
        + " / " +
        formatNumber(quickStats.avgQuickStats.equalityPossessions, "en-US", "1.0-1")
        + " (" + calculatePercentageItem(quickStats.avgQuickStats.equalityGoals, quickStats.avgQuickStats.equalityPossessions) + " %)").style("alternatingColorTableContentStyle").end
    ]);
    quickStatsTableBody.push([
      new Txt("").end,
      new Canvas([
        new Line([0, 0], [50, 0]).lineWidth(2).end,
        new Line([0, 0], [((quickStats.totalQuickStats.equalityGoals / quickStats.totalQuickStats.equalityPossessions) * 50) || 0, 0]).lineColor("#2bc253").lineWidth(2).end
      ]).end,
      new Canvas([
        new Line([0, 0], [50, 0]).lineWidth(2).end,
        new Line([0, 0], [((quickStats.avgQuickStats.equalityGoals / quickStats.avgQuickStats.equalityPossessions) * 50) || 0, 0]).lineColor("#2bc253").lineWidth(2).end
      ]).end
    ]);

    quickStatsTableBody.push([
      new Txt(translateService.instant("7 METERS GOALS %")).style("alternatingColorTableHeaderStyle").end,
      new Txt(
        formatNumber(quickStats.total7MetersGoals, "en-US", "1.0-1")
        + " / " +
        formatNumber((quickStats.total7MetersGoals + quickStats.total7MetersFailedShoots), "en-US", "1.0-1")
        + " (" + calculatePercentageItem(quickStats.total7MetersGoals, (quickStats.total7MetersGoals + quickStats.total7MetersFailedShoots)) + " %)").style("alternatingColorTableContentStyle").end,
      new Txt(
        formatNumber(quickStats.avg7MetersGoals, "en-US", "1.0-1")
        + " / " +
        formatNumber((quickStats.avg7MetersGoals + quickStats.avg7MetersFailedShoots), "en-US", "1.0-1")
        + " (" + calculatePercentageItem(quickStats.avg7MetersGoals, (quickStats.avg7MetersGoals + quickStats.avg7MetersFailedShoots)) + " %)").style("alternatingColorTableContentStyle").end
    ]);
    quickStatsTableBody.push([
      new Txt("").end,
      new Canvas([
        new Line([0, 0], [50, 0]).lineWidth(2).end,
        new Line([0, 0], [((quickStats.total7MetersGoals / (quickStats.total7MetersGoals + quickStats.total7MetersFailedShoots)) * 50) || 0, 0]).lineColor("#2bc253").lineWidth(2).end
      ]).end,
      new Canvas([
        new Line([0, 0], [50, 0]).lineWidth(2).end,
        new Line([0, 0], [((quickStats.avg7MetersGoals / (quickStats.avg7MetersGoals + quickStats.avg7MetersFailedShoots)) * 50) || 0, 0]).lineColor("#2bc253").lineWidth(2).end
      ]).end
    ]);

    pdf.pageSize('A4');

    pdf.background(function (i: any, node: any) {
      if (i === 1) {
        return {
          canvas: [
            {
              type: 'rect',
              x: 0, y: 0, w: 841.89, h: 595.28,
              color: pdfColors.bgRectTableWhite
            }
          ]
        }
      }

      return {
        canvas: [
          {
            type: 'rect',
            x: 0, y: 0, w: 841.89, h: 595.28,
            color: pdfColors.bgBaseLightGrey
          }
        ]
      }
    });

    pdf.pageOrientation('landscape');
    pdf.pageMargins([10, 10, 10, 10]);

    pdf.info({ ...extraPlayerData?.individualPlayerPdfInfo })

    // First page
    // Hanball Field
    pdf.add(
      new SVG(`${handballField}`).alignment('right').width(300).end
    );

    // if svg logo will throw error use png instead
    // this.pdf.add(await new Img('/assets/images/pdfReportImgLogoHandball.png').width(250).alignment('center').build());
    pdf.add(
      // await new Img('/assets/images/pdfReportImgLogoHandball.png').width(250).alignment('center').build()
      new SVG(`${svgTextLogo}`).width('270')
        .alignment('center')
        .absolutePosition(0, 250)
        .end
    );

    addEmptyLines(pdf, 2);

    pdf.add(
      new Table([
        [
          new Cell(
            new Txt('').end
          ).end,

          new Cell(
            await teamLogoResolver(extraPlayerData['playerImageURL'], 'center', 100)
          ).end,

          new Cell(
            new Table([
              [
                new Txt(`Consolidated report of: ${selectedGames.length} Games\n`).end
              ],
              [
                new Txt(`${firstName} ${lastName}, ${playerPosition.toUpperCase()}\n`).end
              ],
              [
                new Txt(`${extraPlayerData['teamName']}`).end
              ]
            ])
              .style('first_page__general_info_cell')
              .heights((row: number) => 20)
              .relativePosition(0, 10)
              .layout({ defaultBorder: false })
              .end)
            .end,

          new Cell(
            new Txt('').end
          ).end
        ]
      ]).layout({ defaultBorder: false }).widths(['30%', '15%', '35%', '20%']).end);

    addEmptyLines(pdf, 7);

    // pdf.add(new Txt(`${globalDataDate(this.globalData.date)}`).alignment('center').style('firstPageGlobalDate').end)
    pdf.add(new Txt(`${pdfCreationDate}`).alignment('center').style('firstPageGlobalDate').end)

    addPageBreak(pdf);
    // End of page #1

    // page 2
    await addHeaderPage(pdf, `${extraData?.pageTitle}`, entityFilterDtoMap, selectedPlayer);
    // player || goalkeeper
    // case player

    const createImageCellsWithPage = (connections, page) => {
      const startIndex = page * 8;
      const endIndex = startIndex + 8;
      const cells = [];

      for (let i = startIndex; i < endIndex && i < connections.length; i += 2) {
        const row = [];

        if (i < connections.length) {
          row.push(connections[i] ? new SVG(connections[i].outerHTML).width(200).end : new Txt('').end);
        }
        if (i + 1 < connections.length) {
          row.push(connections[i + 1] ? new SVG(connections[i + 1].outerHTML).width(200).end : new Txt('').end);
        }

        cells.push(row);
      }

      return cells;
    };

    if (goalkeeperReport) {
      let images = createImageCellsWithPage(indPlshootingGk, 0);

      pdf.add(
          new Table([
            createImageCellsWithPage(indPlshootingGk, 0)
          ])
              .layout('noBorders')
              .widths('*')
              .margin(images.length < 3 ? [140, 80, 0, 0] : [0, 80, 0, 0])
              .end
      );

      if (indPlshootingGk.length > 8) {
        images = createImageCellsWithPage(indPlshootingGk, 1);
        addPageBreak(pdf);
        // End of page #2

        // page 3
        await addHeaderPage(pdf, `${extraData?.pageTitle}`, entityFilterDtoMap, selectedPlayer);


        pdf.add(
            new Table([
              createImageCellsWithPage(indPlshootingGk, 1)
            ])
                .layout('noBorders')
                .widths('*')
                .margin(images.length < 3 ? [140, 80, 0, 0] : [0, 80, 0, 0])
                .end
        );
      }

      if (indPlshootingGk.length > 16) {
        images = createImageCellsWithPage(indPlshootingGk, 2);
        addPageBreak(pdf);
        // End of page #3

        // page 4
        await addHeaderPage(pdf, `${extraData?.pageTitle}`, entityFilterDtoMap, selectedPlayer);

        pdf.add(
            new Table([
              createImageCellsWithPage(indPlshootingGk, 2)
            ])
                .layout('noBorders')
                .widths('*')
                .margin(images.length < 3 ? [140, 80, 0, 0] : [0, 80, 0, 0])
                .end
        );
      }
      pdf.create().getBase64(b64 => {
        modalCtrl.create({
          component: PdfViewerPage,
          componentProps: { data: b64, fileName: firstName + ' ' + lastName + '-gk' },
          cssClass: 'modal-pagescreen',
          showBackdrop: false
        }).then(t => t.present());
        loading.dismiss();
      });

      return;
    } else {

      if (
          (quickStats.totalQuickStats && selectedPlayerPosition !== 'gk')
      ) {
        // quickStatsTableBody.shift();
        quickStatsTableBody.forEach(row => {
          row.forEach((cell, idx) => {
            if (idx === 0) {
              cell['text'] = cell['text'].indexOf('+') >= 0 ? toUpperCase([...cell['text']].map(c => {
                    if (c === '+') {
                      return ''
                    } else if (c === '/') {
                      return ' / '
                    }
                    return c
                  }).join(''))
                  :
                  toUpperCase(cell['text'])
              cell['bold'] = true
              cell['alignment'] = 'left'
            }

            // bottom margin for line charts
            if (row[0]['text'] === '') {
              cell['margin'] = [0, 0, 0, 2]
            }

            if (row[0]['text'] !== '') {
              cell['margin'] = [0, 2, 0, 0]
            }

            cell['style'] = ''
            cell['style'] = ['table__cell__individual_a']
          })
        });

        const quickStatsT = new Table(quickStatsTableBody);
        let quickStatsTablesConfig = {
          quickStatsT,
          tBodyFirstRectHeight: 0,
          tFooterFirstRectHeight: 20,
          tFooterSecondRectHeight: 10
        }
        quickStatsT['_body'].forEach((row, idx) => {
          if (idx !== 0) {
            quickStatsTablesConfig.tBodyFirstRectHeight += 10.16
          }
        });

        pdf.add(
            new Table([
              [
                new Table([
                  [
                    createCustomTable({
                      rectType: 'header',
                      rectWidth: 410,
                      rectColor: pdfColors.bgRectTableLightBlue,
                      firstRectHeight: 28,
                      secondRectHeight: 18
                    })
                  ],
                  [
                    new Cell(await addOverviewTableHeadersOnly('individual_a'))
                        .margin([0, 0, 0, 0]).relativePosition(0, -25).end
                  ],
                  [
                    createCustomTable({
                      rectType: 'body',
                      rectWidth: 410,
                      rectColor: pdfColors.bgRectTableWhite,
                      firstRectHeight: quickStatsTablesConfig.tBodyFirstRectHeight
                    })
                  ],
                  [
                    createCustomTable({
                      rectType: 'footer',
                      rectWidth: 410,
                      rectColor: pdfColors.bgRectTableWhite,
                      firstRectHeight: 15,
                      secondRectHeight: 5
                    })
                  ],
                  [
                    new Cell(
                        new Table([
                          [
                            quickStatsT
                                .layout(layoutTableIndividualA)
                                .widths(['32%', '34%', '34%'])
                                .end
                          ]
                        ])
                            .layout({ defaultBorder: false })
                            .widths('*').end)
                        .margin([0, 0, 0, 0])
                        .relativePosition(0, -(quickStatsTablesConfig.tBodyFirstRectHeight + 20))
                        .end
                  ]
                ])
                    .layout({
                      defaultBorder: false,
                      paddingLeft: function (i, node) { return 0; },
                      paddingRight: function (i, node) { return 0; },
                      paddingTop: function (i, node) { return 0; },
                      paddingBottom: function (i, node) { return 0; }
                    })
                    .margin([0, 0, 0, 0])
                    .widths('*').end,

                new Table([
                  [
                    createCustomTable({
                      rectType: 'header',
                      rectWidth: 155,
                      rectColor: pdfColors.bgRectTableWhite,
                      firstRectHeight: 135,
                      secondRectHeight: 18
                    })
                  ],
                  [
                    new Txt(
                        toUpperCase('GOALS / FAILED SHOTS %')
                    ).style('goals_failed_shorts_chart').relativePosition(0, -133).alignment('center').end
                  ],
                  [
                    new Table([
                      [
                        await new Img(indDoughnut1_PDF.toDataURL()).width(125).alignment('center').margin([0, 2, 0, 2]).build()
                      ]
                    ]).layout({
                      defaultBorder: false,
                      paddingLeft: function (i, node) { return 0; },
                      paddingRight: function (i, node) { return 0; },
                      paddingTop: function (i, node) { return 0; },
                      paddingBottom: function (i, node) { return 0; }
                    })
                        .relativePosition(0, -125)
                        .margin([0, 0, 0, 0])
                        .widths('*').end,
                  ],

                  [
                    new Table([
                      [
                        createCustomTable({
                          rectType: 'body',
                          rectWidth: 7,
                          rectColor: '#36ABE0',
                          firstRectHeight: 7
                        }),

                        new Txt(
                            toUpperCase('Goals scored')
                        )
                            .style('goals_failed_shorts_chart_legend')
                            .alignment('center')
                            .margin([2,0,15,0])
                            .end,

                        createCustomTable({
                          rectType: 'body',
                          rectWidth: 7,
                          rectColor: '#0E375F',
                          firstRectHeight: 7
                        }),

                        new Txt(
                            toUpperCase('Failed Shots')
                        )
                            .style('goals_failed_shorts_chart_legend')
                            .alignment('center')
                            .margin([2,0,0,0])
                            .end
                      ]
                    ])
                        .relativePosition(15, - 15)
                        .layout({
                          defaultBorder: false,
                          paddingLeft: (i, node) => 0,
                          paddingRight: (i, node) => 0,
                          paddingTop: (i, node) => 0,
                          paddingBottom: (i, node) => 0
                        })
                        .end
                  ],

                  // chart #2
                  [
                    createCustomTable({
                      rectType: 'header',
                      rectWidth: 155,
                      rectColor: pdfColors.bgRectTableWhite,
                      firstRectHeight: 135,
                      secondRectHeight: 18
                    })
                  ],
                  [
                    new Txt(
                        toUpperCase('GOALS PER PHASE %')
                    ).style('goals_per_phase_chart').relativePosition(0, -133).alignment('center').end
                  ],
                  [
                    new Table([
                      [
                        await new Img(indDoughnut2_PDF.toDataURL()).width(120).alignment('center').margin([0, 2, 0, 2]).build(),
                      ]
                    ]).layout({
                      defaultBorder: false,
                      paddingLeft: function (i, node) { return 0; },
                      paddingRight: function (i, node) { return 0; },
                      paddingTop: function (i, node) { return 0; },
                      paddingBottom: function (i, node) { return 0; }
                    })
                        .relativePosition(0, -125)
                        .margin([0, 0, 0, 0])
                        .widths('*').end,
                  ],
                  [
                    new Table([
                      [
                        createCustomTable({
                          rectType: 'body',
                          rectWidth: 7,
                          rectColor: '#36ABE0',
                          firstRectHeight: 7
                        }),

                        new Txt(
                            toUpperCase('offense positional')
                        )
                            .style('goals_failed_shorts_chart_legend')
                            .alignment('left')
                            .end,

                        createCustomTable({
                          rectType: 'body',
                          rectWidth: 7,
                          rectColor: '#0E375F',
                          firstRectHeight: 7
                        }),

                        new Txt(
                            toUpperCase('fast\n break')
                        )
                            .style('goals_failed_shorts_chart_legend')
                            .alignment('left')
                            .end,

                        createCustomTable({
                          rectType: 'body',
                          rectWidth: 7,
                          rectColor: '#CBE6F5',
                          firstRectHeight: 7
                        }),

                        new Txt(
                            toUpperCase('counter\n goal')
                        )
                            .style('goals_failed_shorts_chart_legend')
                            .alignment('left')
                            .end
                      ]
                    ])
                        .relativePosition(5, -22)
                        .widths(['7%', '26.3%', '7%', '22.3%', '7%', '30.3%'])
                        .layout({
                          defaultBorder: false,
                          paddingLeft: (i, node) => 0,
                          paddingRight: (i, node) => 0,
                          paddingTop: (i, node) => 0,
                          paddingBottom: (i, node) => 0
                        })
                        .end
                  ],


                ])
                    .layout({
                      defaultBorder: false,
                      paddingLeft: function (i, node) { return 0; },
                      paddingRight: function (i, node) { return 0; },
                      paddingTop: function (i, node) { return 0; },
                      paddingBottom: function (i, node) { return 0; }
                    })
                    .margin([0, 0, 0, 0])
                    .widths('*').end,

                new Table([
                  [
                    indPlshooting[0] ? new SVG(indPlshooting[0].outerHTML).width(175).end : new Txt('').end
                  ],
                  [
                    new Table([
                      [
                        createCustomTable({
                          rectType: 'rect',
                          rectWidth: 173,
                          rectColor: pdfColors.bgRectTableDarktBlue,
                          firstRectX: 0,
                          firstRectY: 5,
                          firstRectHeight: 10,
                        })
                      ]
                    ])
                        .relativePosition(0, -113)
                        .layout({
                          defaultBorder: false,
                          paddingLeft: function (i, node) { return 0; },
                          paddingRight: function (i, node) { return 0; },
                          paddingTop: function (i, node) { return 0; },
                          paddingBottom: function (i, node) { return 0; }
                        })
                        .margin([0, 0, 0, 0])
                        .widths('*').end
                  ],
                  [
                    indPlshooting[1] ? new SVG(indPlshooting[1].outerHTML).width(175).relativePosition(0, -7).end : new Txt('').end,
                  ],
                  [
                    new Table([
                      [
                        createCustomTable({
                          rectType: 'rect',
                          rectWidth: 173,
                          rectColor: '#7EAAD6',
                          firstRectX: 0,
                          firstRectY: 5,
                          firstRectHeight: 10,
                        })
                      ]
                    ])
                        .relativePosition(0, 150)
                        .layout({
                          defaultBorder: false,
                          paddingLeft: function (i, node) { return 0; },
                          paddingRight: function (i, node) { return 0; },
                          paddingTop: function (i, node) { return 0; },
                          paddingBottom: function (i, node) { return 0; }
                        })
                        .margin([0, 0, 0, 0])
                        .widths('*').end
                  ]
                ])
                    .layout('noBorders').alignment('center').margin([0, 10, 0, 0]).widths('*').end
              ]
            ])
                .layout({
                  defaultBorder: false
                }).headerRows(1).margin([0, 0, 0, 10]).widths(['52%', '20%', '28%']).end);

        // page 2 row #3
        const resolveIndividualAggregatedTable = (tableData: Table) => {
          tableData['_body'].shift();
          tableData['_body'].forEach(row => {
            row.forEach((cell, idx) => {
              cell['style'] = ''
              cell['style'] = ['table__cell__individual_b']
              cell['margin'] = [0, 0.2, 0, 0.2]
              if (idx === 0) {
                cell['alignment'] = 'left'
              }
            })
          })
        }
        resolveIndividualAggregatedTable(individualAggregatedTbl3Table)
        resolveIndividualAggregatedTable(individualAggregatedTbl4Table)

        pdf.add(
            new Table([
              [
                new Table([
                  [
                    createCustomTable({
                      rectType: 'header',
                      rectWidth: 405,
                      rectColor: pdfColors.bgRectTableLightBlue,
                      firstRectHeight: 28,
                      secondRectHeight: 18
                    })
                  ],
                  [
                    new Cell(await addOverviewTableHeadersOnly('individual_b'))
                        .margin([0, 0, 0, 0]).relativePosition(0, -25).end
                  ],
                  [
                    createCustomTable({
                      rectType: 'body',
                      rectWidth: 405,
                      rectColor: pdfColors.bgRectTableWhite,
                      firstRectHeight: 125
                    })
                  ],
                  [
                    createCustomTable({
                      rectType: 'footer',
                      rectWidth: 405,
                      rectColor: pdfColors.bgRectTableWhite,
                      firstRectHeight: 15,
                      secondRectHeight: 5
                    })
                  ],
                  [
                    new Cell(
                        individualAggregatedTbl3Table
                            // .dontBreakRows(true)
                            .widths(['33%', '34%', '33%'])
                            .layout(layoutTableBodyGrid)//
                            .end
                    )
                        .relativePosition(2, -138)
                        .end,
                  ]
                ])
                    .dontBreakRows(true)
                    .layout({
                      defaultBorder: false,
                      paddingLeft: function (i, node) { return 0; },
                      paddingRight: function (i, node) { return 0; },
                      paddingTop: function (i, node) { return 0; },
                      paddingBottom: function (i, node) { return 0; }
                    })
                    .margin([0, 0, 0, 0])
                    .widths('*').end,


                // right table
                new Table([
                  [
                    createCustomTable({
                      rectType: 'header',
                      rectWidth: 405,
                      rectColor: pdfColors.bgRectTableLightBlue,
                      firstRectHeight: 28,
                      secondRectHeight: 18
                    })
                  ],
                  [
                    new Cell(await addOverviewTableHeadersOnly('individual_b'))
                        .margin([0, 0, 0, 0]).relativePosition(0, -25).end
                  ],
                  [
                    createCustomTable({
                      rectType: 'body',
                      rectWidth: 405,
                      rectColor: pdfColors.bgRectTableWhite,
                      firstRectHeight: 125
                    })
                  ],
                  [
                    createCustomTable({
                      rectType: 'footer',
                      rectWidth: 405,
                      rectColor: pdfColors.bgRectTableWhite,
                      firstRectHeight: 15,
                      secondRectHeight: 5
                    })
                  ],
                  [
                    new Cell(
                        individualAggregatedTbl4Table
                            .widths(['33%', '34%', '33%'])
                            .layout(layoutTableBodyGrid)
                            .end
                    )
                        .relativePosition(2, -138)
                        .end,
                  ],
                ])
                    .dontBreakRows(true)
                    .layout({
                      defaultBorder: false,
                      paddingLeft: function (i, node) { return 0; },
                      paddingRight: function (i, node) { return 0; },
                      paddingTop: function (i, node) { return 0; },
                      paddingBottom: function (i, node) { return 0; }
                    })
                    .margin([0, 0, 0, 0])
                    .widths('*').end,
              ]

            ])
                .layout({
                  defaultBorder: false,
                })
                .widths(['50%', '50%']).end
        )
      }

      // case goalkeeper completly defferent page structure
      // case goalkeper
      if (
          (quickStats.totalQuickStats && selectedPlayerPosition === 'gk')
      ) {
        // quickStatsTableBody.shift();
        quickStatsTableBody.forEach(row => {
          row.forEach((cell, idx) => {
            if (idx === 0) {
              cell['text'] = cell['text'].indexOf('+') >= 0 ? toUpperCase([...cell['text']].map(c => {
                    if (c === '+') {
                      return '\n'
                    } else if (c === '/') {
                      return ' / '
                    }
                    return c
                  }).join(''))
                  :
                  toUpperCase(cell['text'])
              cell['bold'] = true
              cell['alignment'] = 'left'
            }

            // bottom margin for line charts
            if (row[0]['text'] === '') {
              cell['margin'] = [0, 0, 0, 2]
            }

            if (row[0]['text'] !== '') {
              cell['margin'] = [0, 2, 0, 0]
            }

            cell['style'] = ''
            cell['style'] = ['table__cell__individual_a']
          })
        });


        const resolveIndividualAggregatedTable = (tableData: Table) => {
          tableData['_body'].shift()
          tableData['_body'].forEach(row => {
            row.forEach((cell, idx) => {
              cell['style'] = ''
              cell['style'] = ['table__cell__individual_b']
              cell['margin'] = [0, 3, 0, 0]
              if (idx === 0) {
                cell['alignment'] = 'left'
              }
              else {
                cell['fontSize'] = 10
              }
            })
          })
        }
        resolveIndividualAggregatedTable(individualAggregatedTbl3Table)
        resolveIndividualAggregatedTable(individualAggregatedTbl4Table)

        pdf.add(
            new Table([
              [
                new Table([
                  [
                    new Table([
                      [
                        createCustomTable({
                          rectType: 'header',
                          rectWidth: 483,
                          rectColor: pdfColors.bgRectTableLightBlue,
                          firstRectHeight: 28,
                          secondRectHeight: 18
                        })
                      ],
                      [
                        new Cell(await addOverviewTableHeadersOnly('individual_b'))
                            .margin([0, 0, 0, 0]).relativePosition(0, -25).end
                      ],
                      [
                        createCustomTable({
                          rectType: 'body',
                          rectWidth: 483,
                          rectColor: pdfColors.bgRectTableWhite,
                          firstRectHeight: 180
                        })
                      ],
                      [
                        createCustomTable({
                          rectType: 'footer',
                          rectWidth: 483,
                          rectColor: pdfColors.bgRectTableWhite,
                          firstRectHeight: 15,
                          secondRectHeight: 5
                        })
                      ],
                      [
                        new Cell(
                            individualAggregatedTbl3Table
                                // .dontBreakRows(true)
                                .widths(['33%', '34%', '33%'])
                                .layout(layoutTableBodyGrid)
                                .heights((row: number) => 15.5)
                                .end
                        )
                            .relativePosition(2, -195)
                            .end,
                      ],
                    ])
                        .dontBreakRows(true)
                        .layout({
                          defaultBorder: false,
                          paddingLeft: function (i, node) { return 0; },
                          paddingRight: function (i, node) { return 0; },
                          paddingTop: function (i, node) { return 0; },
                          paddingBottom: function (i, node) { return 0; }
                        })
                        .margin([0, 0, 0, 0])
                        .widths('*').end
                  ],

                  [
                    new Table([
                      [
                        createCustomTable({
                          rectType: 'header',
                          rectWidth: 483,
                          rectColor: pdfColors.bgRectTableLightBlue,
                          firstRectHeight: 28,
                          secondRectHeight: 18
                        })
                      ],
                      [
                        new Cell(await addOverviewTableHeadersOnly('individual_b'))
                            .margin([0, 0, 0, 0]).relativePosition(0, -25).end
                      ],
                      [
                        createCustomTable({
                          rectType: 'body',
                          rectWidth: 483,
                          rectColor: pdfColors.bgRectTableWhite,
                          firstRectHeight: 180
                        })
                      ],
                      [
                        createCustomTable({
                          rectType: 'footer',
                          rectWidth: 483,
                          rectColor: pdfColors.bgRectTableWhite,
                          firstRectHeight: 15,
                          secondRectHeight: 5
                        })
                      ],
                      [
                        new Cell(
                            individualAggregatedTbl4Table
                                .widths(['33%', '34%', '33%'])
                                .layout(layoutTableBodyGrid)
                                .heights((row: number) => 15.5)
                                .end
                        )
                            .relativePosition(2, -195)
                            .end,
                      ],
                    ])
                        .dontBreakRows(true)
                        .layout({
                          defaultBorder: false,
                          paddingLeft: function (i, node) { return 0; },
                          paddingRight: function (i, node) { return 0; },
                          paddingTop: function (i, node) { return 0; },
                          paddingBottom: function (i, node) { return 0; }
                        })
                        .margin([0, 0, 0, 0])
                        .widths('*').end
                  ]
                ])
                    .layout({
                      defaultBorder: false,
                      paddingLeft: function (i, node) { return 0; },
                      paddingRight: function (i, node) { return 0; },
                      paddingTop: function (i, node) { return 0; },
                      paddingBottom: function (i, node) { return 0; }
                    })
                    .margin([0, 0, 0, 0])
                    .widths('*').end,

                new Table([
                  [
                    indPlshooting[0] ? new SVG(indPlshooting[0].outerHTML).width(295).end : new Txt('').end
                  ],
                  [
                    new Table([
                      [
                        createCustomTable({
                          rectType: 'rect',
                          rectWidth: 292,
                          rectColor: pdfColors.bgRectTableDarktBlue,
                          firstRectX: 0,
                          firstRectY: 5,
                          firstRectHeight: 10,
                        })
                      ]
                    ])
                        .relativePosition(0, -180)
                        .layout({
                          defaultBorder: false,
                          paddingLeft: function (i, node) { return 0; },
                          paddingRight: function (i, node) { return 0; },
                          paddingTop: function (i, node) { return 0; },
                          paddingBottom: function (i, node) { return 0; }
                        })
                        .margin([0, 0, 0, 0])
                        .widths('*').end
                  ],
                  [
                    indPlshooting[1] ? new SVG(indPlshooting[1].outerHTML).width(295).relativePosition(0, -7).end : new Txt('').end,
                  ],
                  [
                    new Table([
                      [
                        createCustomTable({
                          rectType: 'rect',
                          rectWidth: 293,
                          rectColor: '#7EAAD6',
                          firstRectX: 0,
                          firstRectY: 5,
                          firstRectHeight: 10,
                        })
                      ]
                    ])
                        .relativePosition(0, 267)
                        .layout({
                          defaultBorder: false,
                          paddingLeft: function (i, node) { return 0; },
                          paddingRight: function (i, node) { return 0; },
                          paddingTop: function (i, node) { return 0; },
                          paddingBottom: function (i, node) { return 0; }
                        })
                        .margin([0, 0, 0, 0])
                        .widths('*').end
                  ]
                ]).layout('noBorders').alignment('center').margin([0, 10, 0, 0]).widths('*').end
              ]
            ]).layout({
              defaultBorder: false
            })
                .headerRows(1).margin([0, 0, 0, 10]).widths(['60%', '40%']).end)
      }
      addPageBreak(pdf);
      // end page 2


      // page 3
      const renderLastGamePage = async (lastGamesTableBody, idx) => {
        const lastGamesTables = new Table(lastGamesTableBody).headerRows(1)
            .layout(layoutTableBodyGridTest2)
            .heights((row: number) => 15);

        const topMargin = 7
        const resolveLastGamesTableCells = (tableData: Table) => {
          // tableData['_body'].shift()
          tableData['_body'].forEach(row => {
            row.forEach((cell, idx) => {
              cell['style'] = ''
              cell['style'] = ['table__cell__individual_c']
              cell['margin'] = idx <= 1 ? [0, 1, 0, 1] : [0, topMargin, 0, 5]
              cell['border'] = [true, true, true, true]

              if (idx <= 1) {
                cell['alignment'] = 'left'
              }
            })
          })
        }
        resolveLastGamesTableCells(lastGamesTables)


        const lastGamesTableWidths = selectedPlayerRol === 'gk' ?
            [
              '5%', '22.5%', '7.25%', '7.25%', '7.25%',
              '7.25%', '7.25%', '7.25%', '7.25%', '7.25%', '7.25%', '7.25%'
            ]
            :
            [
              '5%', '25%',
              '5.85%', '5.85%', '5.85%', '5.85%', '5.85%',
              '5.85%', '5.85%', '5.85%', '5.85%', '5.85%',
              '5.85%', '5.85%'
            ];

        await addHeaderPage(pdf, `${extraData?.pageTitle}`, entityFilterDtoMap, selectedPlayer);
        pdf.add(new Txt(toUpperCase(translateService.instant('LAST GAMES'))).fontSize(11).margin([0, 5, 0, 5]).end);//

        pdf.add(

            new Table([
              [
                createCustomTable({
                  rectType: 'header',
                  rectWidth: 818,
                  rectColor: pdfColors.bgRectTableLightBlue,
                  firstRectHeight: 28,
                  secondRectHeight: 18
                })
              ],
              [
                new Cell(await addOverviewTableHeadersOnly('individual_c'))
                    .margin([0, 0, 0, 0]).relativePosition(0, -25).end
              ],
              [
                new Cell(

                    lastGamesTables
                        .dontBreakRows(true)
                        .widths(lastGamesTableWidths)
                        .end
                )
                    .end,
              ]
            ])
                .layout({
                  defaultBorder: false,
                  paddingLeft: function (i, node) { return 0; },
                  paddingRight: function (i, node) { return 0; },
                  paddingTop: function (i, node) { return 0; },
                  paddingBottom: function (i, node) { return 0; }
                })
                .margin([0, 0, 0, 0])
                .widths('*').end
        );

        if (idx !== alfaArr.length - 1) {
          addPageBreak(pdf);
        }
      }

      lastGamesTableBody.shift()
      let alfaArr = []
      let deltaArr = []
      lastGamesTableBody.forEach((row, idx) => {
        deltaArr.push(row)
        if (deltaArr.length === 16 || idx === lastGamesTableBody.length - 1) {
          alfaArr.push(deltaArr)
          deltaArr = [];
        }
      })
      for (let idx = 0; idx < alfaArr.length; idx++) {
        await renderLastGamePage(alfaArr[idx], idx)
      }

      // end of page 3


      // page 4
      const gameSystemTableBody = [];
      gameSystemTbl.forEach(element => {
        gameSystemTableBody.push([
          // new Cell(new Txt(element.categoryName + " - " + element.actionDisplayName).style('table__cell__individual_f').end).fillColor(getGameSystemColor(element.colorCode)).end,

          new Cell(new Txt(element.categoryName + " - " + element.actionDisplayName).style('table__cell__individual_f').end)
              .color(getGameSystemColor(element.colorCode)).alignment('left').end,

          new Txt(element.numberOfActionsPlayed).style('table__cell__individual_f').end,
          new Txt(element.numberOfGoals).style('table__cell__individual_f').end,
          new Txt(element.numberOfSaves).style('table__cell__individual_f').end,
          new Txt(element.numberOfPostOuts).style('table__cell__individual_f').end,
          new Txt(element.numberOfActionsPlayedWith7Meters).style('table__cell__individual_f').end,


          new Txt(element.numberOfFault).style('table__cell__individual_f').end,
          new Txt(element.numberOfSuspension).style('table__cell__individual_f').end,
          new Txt(element.numberOfLostBall).style('table__cell__individual_f').end,

          new Txt(decimalToFixed(element.percentageGoals) + "%").style('table__cell__individual_f').end,
          new Txt(decimalToFixed(element.percentageNoGoals) + "%").style('table__cell__individual_f').end,
          new Txt(decimalToFixed(element.percentage7MetersGoals) + "%").style('table__cell__individual_f').end
        ]);
      });

      gameSystemTableBody.forEach(row => {
        row.forEach(cell => {
          cell['margin'] = [0, 1, 0, 0]
        })
      });

      if (gameSystemTbl && gameSystemTbl?.length > 0) {
        addPageBreak(pdf);
        //  await addHeaderPage(pdf, toUpperCase('OVERVIEW'), selectedTeam);

        await addHeaderPage(pdf, `${extraData?.pageTitle}`, entityFilterDtoMap, selectedPlayer);

        pdf.add(new Txt(toUpperCase(translateService.instant('GAME SYSTEM'))).style('team_table_info').alignment('left').margin([10, 5, 0, 5]).end);

        pdf.add(
            new Table([
              [
                createCustomTable({
                  rectType: 'header',
                  rectWidth: 818,
                  rectColor: pdfColors.bgRectTableLightBlue,
                  firstRectHeight: 25,
                  secondRectHeight: 15
                })
              ],
              [
                new Cell(await addOverviewTableHeadersOnly('individual_f'))
                    .margin([0, 0, 0, 0]).relativePosition(0, -23).end
              ],
              [
                new Cell(
                    new Table(gameSystemTableBody)
                        .dontBreakRows(true)
                        .widths([
                          '20%', '7.27%','7.27%','7.27%','7.27%',
                          '7.27%','7.27%','7.27%','7.27%','7.27%',
                          '7.27%','7.27%'
                        ])
                        .layout(layoutTableBodyGridTest2)
                        .heights((row: number) => 8)
                        .end
                ).end,
              ],
            ])
                .layout({
                  defaultBorder: false,
                  paddingLeft: function (i, node) { return 0; },
                  paddingRight: function (i, node) { return 0; },
                  paddingTop: function (i, node) { return 0; },
                  paddingBottom: function (i, node) { return 0; }
                })
                .margin([0, 0, 0, 0])
                .widths('*').end
        );
      }
      // end of page 4

      pdf.create().getBase64(b64 => {
        modalCtrl.create({
          component: PdfViewerPage,
          componentProps: { data: b64, fileName: firstName + ' ' + lastName },
          cssClass: 'modal-pagescreen',
          showBackdrop: false
        }).then(t => t.present());
        loading.dismiss();
      });
    }

  });
}

export function calculatePercentageItem(dividend: number, divisor: number): string {
  if (dividend && divisor) {
    return (divisor === 0 ? 0 : 100 * (dividend / divisor)).toFixed(0);
  }
  return '0';
}

export function addEmptyLines(pdf: PdfMakeWrapper, n: number) {
  for (var i = 0; i < n; i++) {
    pdf.add(new Txt(' ').end);
  }
}

export function addPageBreak(pdf: PdfMakeWrapper) {
  pdf.add(new Txt('').pageBreak('after').end);
}

export function addHorizontalLine(pdf: PdfMakeWrapper) {
  pdf.add(new Table([
    [''],
    ['']
  ]).widths(['*']).height(5).headerRows(1).layout('headerLineOnly').alignment('center').end);
}

export async function addHeaderPage(pdf: PdfMakeWrapper, pageTitle: string, entityFilterDtoMap, selectedPlayer) {
  pdf.add(new Table([
    [
      new Cell(new SVG(logoWithTextSVG)
        .width('40').end).end,

      new Cell(
        await teamLogoResolver(extraPlayerData['playerImageURL'], 'center', 50)
      ).end,

      new Cell(
        new Table([
          [
            new Txt(`${firstName}`).style(['headerPlayerCell']).end
          ],
          [
            new Txt(`${lastName}`).style(['headerPlayerCell']).end
          ]
        ])
          .layout({defaultBorder: false})
          .relativePosition(0, 10)
          .end
      ).end,

      new Cell(
        new Txt([
          new Txt(`${pageTitle}\n${firstName}\n${lastName}\n`).style(['headerPlayerCell'])
            .alignment('center')
            .end,
          new Txt(`${PDF_URL}`).style(['headerPlayerCell', 'headerPlayerCellSmall'])
            .decoration('underline').alignment('center')
            .end
        ])
          .relativePosition(0, 5)
          .end
      ).end,

      new Cell(
        new Txt([
          new Txt(`${firstName} ${lastName}\n`).style(['headerPlayerCell', 'headerPlayerCellSmall']).alignment('right').lineHeight(1.5).end,
          new Txt(`${pdfCreationDate}`).style(['headerPlayerCell', 'headerPlayerCellSmall']).alignment('right').lineHeight(1.5).end,
        ])
          .relativePosition(0, 20).end
      ).end
    ]
  ])
    .layout({ defaultBorder: false })
    .widths(['10%', '10%', '15%', '30%', '35%'])
    .end);
  addHorizontalLine(pdf);
  // addPageBreak(pdf);
}

async function addOverviewTableHeadersOnly(
  tableType?: string
) {
  let widths = []
  let headerTitles: any = []

  switch (tableType) {
    case 'individual_a':
      widths = [
        '32%', '34%', '34%'
      ];
      headerTitles = [
        'Totals',
        toUpperCase(translateServices.instant('PLAYER TOTALS')),
        toUpperCase(translateServices.instant('PLAYER AVERAGE'))
      ];
      break;

    case 'individual_b':
      widths = [
        '33%', '34%', '33%'
      ];
      headerTitles = [
        'All Events',
        toUpperCase(translateServices.instant('Total')),
        toUpperCase(translateServices.instant('Average'))
      ];
      break;

    case 'individual_c':
      widths = selectedPlayerRol === 'gk' ?
        [
          '5%', '22.5%', '7.25%', '7.25%', '7.25%',
          '7.25%', '7.25%', '7.25%', '7.25%', '7.25%', '7.25%', '7.25%'
        ]
        :
        [
          '5%', '25%',
          '5.85%', '5.85%', '5.85%', '5.85%', '5.85%',
          '5.85%', '5.85%', '5.85%', '5.85%', '5.85%',
          '5.85%', '5.85%'
        ];

      headerTitles = selectedPlayerRol === 'gk' ?
        [
          toUpperCase(translateServices.instant('Game Data')),
          toUpperCase(translateServices.instant('Teams Name')),
          toUpperCase(translateServices.instant('Time Played')),
          toUpperCase(translateServices.instant('Played Poses')),
          toUpperCase(translateServices.instant('Possession Events')),

          toUpperCase(translateServices.instant('Goals')),
          toUpperCase(translateServices.instant('Saves')),
          toUpperCase(translateServices.instant('Lost Balls')),
          `${toUpperCase(translateServices.instant('Shoot Eff'))} %`,
          toUpperCase(translateServices.instant('Player Score')),

          toUpperCase(translateServices.instant('Gk Saves 7 Meters')),
          toUpperCase(translateServices.instant('Gk Received Goals 7 Meters'))
        ]
        :
        [

          toUpperCase(translateServices.instant('Game Data')),
          toUpperCase(translateServices.instant('Teams Name')),
          toUpperCase(translateServices.instant('Time Played')),
          toUpperCase(translateServices.instant('Played Poses')),
          toUpperCase(translateServices.instant('Possession Events')),

          toUpperCase(translateServices.instant('Goals')),
          toUpperCase(translateServices.instant('Assists')),
          toUpperCase(translateServices.instant('Failed Shots')),
          toUpperCase(translateServices.instant('Post Outs')),
          toUpperCase(translateServices.instant('Lost Balls')),

          toUpperCase(translateServices.instant('Lost Balls Perc')),
          `${toUpperCase(translateServices.instant('Eff'))} %`,
          `${toUpperCase(translateServices.instant('Shoot Eff'))} %`,
          toUpperCase(translateServices.instant('Player Scor'))
        ];
      break;

      case 'individual_f':
        widths = [
          '19.6%', '7.47%','7.47%','7.27%','7.27%',
          '7.27%','7.27%','7.27%','7.27%','7.27%',
          '7.27%','7.27%'
        ]
        headerTitles = [
          toUpperCase('SYSTEM'),
          toUpperCase('TIMES PLAYED'),
          toUpperCase('N° GOALS'),
          toUpperCase('N° SAVES'),
          toUpperCase('N° POST / OUT'),
          toUpperCase('N° 7 METERS'),

          toUpperCase('N° FAULT'),
          toUpperCase('N° SUSPENSION'),
          toUpperCase('N° LOST BALL'),

          toUpperCase('GOALS %'),
          toUpperCase('NO GOALS %'),
          toUpperCase('Goals 7 Meters %')
        ];
      break;

    default:
      break;
  }

  let cellrows: Object[][] = [
    getArrayHeaderCellsFromStrings(headerTitles, null, null, 'table__header__cell')
  ];

  cellrows.forEach(
    cellRow => {
      cellRow.forEach(cell => {
        switch (tableType) {
          case 'individual_a':
            cell['style'] = ''
            cell['style'] = 'header__cell__individual_a'
            break;

          case 'individual_b':
            cell['style'] = ''
            cell['style'] = 'header__cell__individual_b'
            break;

          case 'individual_c':
            cell['style'] = ''
            cell['style'] = 'header__cell__individual_c'
            break;
          case 'individual_f':
            cell['style'] = ''
            cell['style'] = 'header__cell__individual_f'
            break;

          default:
            break;
        }
      })
    }
  );

  cellrows[0][0]['alignment'] = 'left';
  cellrows[0][0]['margin'] = [10, 0, 0, 0];

  switch (tableType) {
    case 'individual_a':
    case 'individual_b':
    case 'individual_c':
    case 'individual_f':
      return new Table(cellrows).headerRows(1)
        .widths(widths).margin(0)
        .end;
    default:
      break;
  }
}

function createCustomTable(config: RectConfig, data?: any,) {
  const {
    rectType,
    rectWidth,
    rectColor,

    firstRectX,
    firstRectY,
    firstRectHeight,
    firstRectLineColor,
    firstRectColor,

    secondRectX,
    secondRectY,
    secondRectHeight
  } = config;

  switch (rectType) {
    case 'header':
      return [
        new Canvas(
          [
            new Rect([firstRectX ? firstRectX : 0, firstRectY ? firstRectY : 10], [rectWidth, firstRectHeight ? firstRectHeight : 30]).lineColor(rectColor).color(rectColor).round(10).end,
            new Rect([secondRectX ? secondRectX : 0, secondRectY ? secondRectY : 20], [rectWidth, secondRectHeight ? secondRectHeight : 20]).lineColor(rectColor).color(rectColor).end,
          ]
        ).end
      ];
    case 'body':
      return [
        new Canvas(
          [
            new Rect([firstRectX ? firstRectX : 0, firstRectY ? firstRectY : 0], [rectWidth, firstRectHeight ? firstRectHeight : 40]).lineColor(rectColor).color(rectColor).end,
          ]
        ).end
      ];
    case 'footer':
      return [
        new Canvas(
          [
            new Rect([firstRectX ? firstRectX : 0, firstRectY ? firstRectY : 0], [rectWidth, firstRectHeight ? firstRectHeight : 28]).lineColor(rectColor).color(rectColor).round(10).end,
            new Rect([secondRectX ? secondRectX : 0, secondRectY ? secondRectY : 0], [rectWidth, secondRectHeight ? secondRectHeight : 18]).lineColor(rectColor).color(rectColor).end
          ]
        ).end,
      ];
    case 'rect':
      return [
        new Canvas(
          [
            new Rect([firstRectX ? firstRectX : 0, firstRectY ? firstRectY : 0], [rectWidth, firstRectHeight ? firstRectHeight : 28]).lineColor(rectColor).color(rectColor).round(10).end,
          ]
        ).end,
      ];
    case 'rect-with-border':
      return [
        new Canvas(
          [
            new Rect([firstRectX ? firstRectX : 0, firstRectY ? firstRectY : 0], [rectWidth, firstRectHeight ? firstRectHeight : 28]).lineColor(firstRectLineColor).color(firstRectColor).round(10).end,
          ]
        ).end,
      ];
  }
}

function getArrayHeaderCellsFromStrings(headerTitles: Object[], teamNameBgColor: string, teamNameColor: string, headerCellClass?: string) {
  var cellsArray: Object[] = [];
  for (let i = 0; i < headerTitles.length; i++) {
    let cell = new Cell(new Txt('' + (headerTitles[i]['label'] ?? headerTitles[i])).end).fillColor(teamNameBgColor).color(teamNameColor).alignment('center').margin([3, 1]).border([false, false, false, false]).style(headerCellClass ? headerCellClass : '');
    if (headerTitles[i]['cspan']) cell = cell.colSpan(headerTitles[i]['cspan']);
    if (headerTitles[i]['bold'] !== false) cell = cell.bold();
    cellsArray.push(cell.end);
  }
  return cellsArray;
}
