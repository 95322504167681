/**
 * handball.ai - User Registration, Authorization and internal API
 * User Registration, Authentication, Authorization, Account Management and Internal HAI Services
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface VideoScoutingConnectChallenges { 
    /**
     * Blob slice start
     */
    sliceStart: number;
    /**
     * Blob slice end
     */
    sliceEnd: number;
    /**
     * resulting challenge hash
     */
    challengeResult: string;
}

