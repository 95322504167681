import { Injectable } from "@angular/core";
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { BehaviorSubject, concatMap, from, Observable, switchMap, throwError } from 'rxjs';
import { catchError, filter, map, mergeMap, take } from 'rxjs/operators';
import { Router } from "@angular/router";
import { StorageService } from "../storage-service/storage.service";
import { LoginWithRefresherDto, RegistrationService, TokenDto } from '../../api/hai-api';
import { AccountEquipoService } from '../account-equipo/account-equipo.service';
import { VersionServiceProvider } from "../version-service";
import { environment } from "src/environments/environment";

@Injectable()
export class TokenInterceptorService implements HttpInterceptor {

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
      private readonly versionService: VersionServiceProvider,
      private readonly tokenStorageService: StorageService,
      private readonly router: Router,
      private readonly registrationService: RegistrationService,
      private readonly storageService: StorageService,
      public readonly accountEquipoService: AccountEquipoService,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.modifyRequest(req).pipe(
        mergeMap((r) => next.handle(r)),
        catchError((err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.url.includes('loginwithrefresher')) {
              return throwError(() => err);
            }

            if (!location.pathname.startsWith("/public")) {
              if (err.status === 403) {
                if (!location.pathname.startsWith("/auth")) {
                  this.router.navigate(["/"]);
                }
              } else if (err.status === 401) {
                if (!this.isRefreshing) {
                  this.isRefreshing = true;
                  this.refreshTokenSubject.next(null);

                  return from(this.tokenStorageService.refresherToken)
                      .pipe(
                          switchMap((refresherToken) => {
                            const refresherDto: LoginWithRefresherDto = {
                              refresherToken: refresherToken
                            };
                            return this.registrationService.registrationControllerLoginWithRefresher(refresherDto)
                                .pipe(
                                    concatMap((token: TokenDto) => {
                                      this.isRefreshing = false;
                                      this.refreshTokenSubject.next(token.accessToken);
                                      this.storageService.saveToken(token);
                                      return next.handle(this.addAuthHeader(req, token.accessToken));
                                    }),
                                    catchError((error) => {
                                      this.isRefreshing = false;
                                      this.tokenStorageService.wipeOutTokens();
                                      if (!location.pathname.startsWith("/auth")) {
                                        this.router.navigate(['/auth/login']);
                                      }
                                      this.accountEquipoService.fireAccountEquipoChange(null);
                                      return throwError(() => error);
                                    })
                                );
                          })
                      );
                } else {
                   return this.refreshTokenSubject.pipe(
                    filter(token => token !== null),
                    take(1),
                    switchMap((token) => {
                      const newReq = this.addAuthHeader(req, token);
                      return next.handle(newReq);
                    })
                  );
                }
              }
            }
          }
          return throwError(() => err);
        })
    );
  }

  private addAuthHeader(req: HttpRequest<any>, token: string | null): HttpRequest<any> {
    if (token) {
      return req.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
    }
    return req;
  }

  private modifyRequest(request: HttpRequest<any>): Observable<HttpRequest<any>> {
    return this.tokenStorageService.getAccessTokenAsObservable().pipe(
      map((token) => {
        let clonedReq: HttpRequest<any>;
        if (token && !request.headers.has("prevent-auth")) {
          let ngswBypass = false;
          if (request.url.includes(environment.apiPath) || request.url.includes(environment.playersApiPath)) {
            ngswBypass = true; // Bypass service worker for API requests
          }
          clonedReq = request.clone({
            setHeaders: {
              Authorization: `Bearer ${token}`,
              'X-Hai-App-Version': `${this.versionService.getVersion()}`,
              ...(ngswBypass ? {'ngsw-bypass': 'true'} : {}),
            },
          });
          return clonedReq;
        } else {
          return request;
        }
      })
    );
  }
}
