<div class="video-container">
    <div class="video-content">
        <div class="video-header">
            <span *ngIf="type === 'Video'">{{'Selected Video' | translate}}</span>
            <span *ngIf="type === 'Image'">{{'Selected Image' | translate}}</span>
        </div>
        <video *ngIf="type === 'Video'" id='video' #videoPlayer width="100%" controls [muted]="true" disablepictureinpicture controlslist="nodownload noplaybackrate">
            <source [src]="src|safe:'resourceUrl'" type="video/mp4">
        </video>
        <img *ngIf="type === 'Image'" [src]="src" alt="img" class="fullscreen-img">
    </div>

    <div class="video-actions w-100">
        <ion-button class="button button-block" [fill]="'outline'" (click)="cancel()">
            <ion-label>{{'Cancel'|translate}}</ion-label>
        </ion-button>
    </div>
</div>
