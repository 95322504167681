import { VideoScoutingConnectChallenges } from "src/app/api/hai-api";

export async function generateHashFromBlob(blob: Blob, startPercentage = 0.37, endPercentage = 0.42): Promise<string> {
  // Step 1: Calculate the portion of the blob to hash
  const start = Math.floor(blob.size * startPercentage);
  const end = Math.floor(blob.size * endPercentage);

  // Step 2: Slice the blob to extract only the portion
  const slicedBlob = blob.slice(start, end);

  // Step 3: Convert the sliced blob to an ArrayBuffer
  const arrayBuffer = await slicedBlob.arrayBuffer();

  // Step 4: Generate the hash using SubtleCrypto API
  const hashBuffer = await crypto.subtle.digest('SHA-256', arrayBuffer);

  // Step 5: Convert the hash buffer to a hexadecimal string
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');

  return hashHex;
}

export async function generateVideoChallengesFromBlob(blob: Blob): Promise<VideoScoutingConnectChallenges[]> {
  const challenges: VideoScoutingConnectChallenges[] = [];

  // Generate 5 random challenges
  for (let i = 0; i < 5; i++) {
    const startPercentage = Math.trunc(Math.random() * 0.9 * 100); // Random value between 0 and 90%
    const hashChunkLenght = Math.trunc(1 + (Math.random() * 0.04 * 100)); // Random value between 1% and 5% (maximum to hash a 5% of full video)
    const endPercentage = startPercentage + hashChunkLenght; // Random value between 1% and 95%

    challenges.push({
      sliceStart: startPercentage,
      sliceEnd: endPercentage,
      challengeResult: await generateHashFromBlob(blob, startPercentage / 100, endPercentage / 100)
    });
  }

  return challenges;
}

export async function createBlobFromUrl(url: string) {
  try {
    // Step 1: Fetch the file as a binary stream
    const response = await fetch(url, {headers: {'ngsw-bypass': 'true'}});

    // Check if the response is successful
    if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);

    // Step 2: Convert the response body to a Blob
    const blob = await response.blob();

    // Step 3: Check the blob type
    console.log("Blob created:", blob);
    console.log("Blob size:", blob.size, "Blob type:", blob.type);

    return blob;
  } catch (error) {
    console.error("Error creating blob:", error);
    throw error;
  }
}
