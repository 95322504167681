import { QuickStatsColumnModel, ExecutionPositionTypes, PhasesActionTypes } from '@handballai/stats-calculation';
import { PlayByPlayDto, PlayerStatsAggregateDto, PlayerStatsDto, TeamsStatsDto, TeamStatsAggregateDto } from 'src/app/api/hai-api';
import { formatTimeToHoursMinutesAndSeconds, timerFormatHelper } from 'src/app/shared-services/helper/timer-format.helper';
import { GameSystemStatsViewModel } from 'src/app/shared-services/statistics/playbyplay/consumer/game-system/model/game-system-view.model';
import { GameSystemStatsModel } from 'src/app/shared-services/statistics/playbyplay/consumer/game-system/model/game-system-stats.model';
import { PlayersTotalGameModel } from './dashboard.model';
import { transformGameOpening } from 'src/app/shared-services/helper/game-system.helper';
import { sortByCategoryAndNumberOfActions } from 'src/app/shared-services/statistics/playbyplay/consumer/game-system/game-system.helper';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

type AssistFilter = 'totalAssist' | 'avgAssist';

export const objTotal = (
    dto: object,
    filter: string
): number =>
        Object.keys(dto)
            .filter(key => key.includes(filter))
            .reduce((sum, key) => sum + (+dto[key]), 0);

export const totalAssists = (
    dto: TeamStatsAggregateDto|PlayerStatsAggregateDto,
    assistFilter: AssistFilter = 'totalAssist'
): number => objTotal(dto, assistFilter);

export const totalDirectAssists = (
    dto: TeamsStatsDto|PlayerStatsDto,
): number => objTotal(dto, 'assist');

export const avgAssists = (
    dto: TeamStatsAggregateDto|PlayerStatsAggregateDto,
    assistFilter: AssistFilter = 'avgAssist'
): number => objTotal(dto, assistFilter);

export const possessionEventsPlayerDirect = (
    dto: PlayerStatsDto,
    filter: PhasesActionTypes|'EQUALITY'|'SUPERIORITY'|'all' = 'all'
): number => {
    let res = 0;
    if (filter == 'OFFENSE_POSITIONAL' || filter == 'all') {
        res +=  (+dto.goalsOffensePositional);
        res +=  (+dto.failedShotsOffensePositional);
        res +=  (+dto.postOutOffensePositional);
        res +=  (+dto.lostBallsOffensePositional);
    }
    if (filter == 'COUNTER_GOAL' || filter == 'all') {
        res +=  (+dto.goalsCounterGoal);
        res +=  (+dto.failedShotsCounterGoal);
        res +=  (+dto.postOutCounterGoal);
        res +=  (+dto.lostBallsCounterGoal);
    }
    if (filter == 'FAST_BREAK' || filter == 'all') {
        res +=  (+dto.goalsFastBreak);
        res +=  (+dto.failedShotsFastBreak);
        res +=  (+dto.postOutFastBreak);
        res +=  (+dto.lostBallsFastBreak);
    }
    if (filter == 'EQUALITY') {
        res +=  (+dto.goalsEquality);
        res +=  (+dto.failedShotsEquality);
        res +=  (+dto.postOutEquality);
        res +=  (+dto.lostBallsEquality);
    }
    if (filter == 'SUPERIORITY') {
        res +=  (+dto.goalsSuperiority);
        res +=  (+dto.failedShotsSuperiority);
        res +=  (+dto.postOutSuperiority);
        res +=  (+dto.lostBallsSuperiority);
    }
    return res;
};
export const possessionEventsPlayer = (
    dto: PlayerStatsAggregateDto,
    filter: PhasesActionTypes|'EQUALITY'|'SUPERIORITY'|'all' = 'all'
): number => {
    let res = 0;
    if (filter == 'OFFENSE_POSITIONAL' || filter == 'all') {
        res +=  (+dto.totalGoalsOffensePositional);
        res +=  (+dto.totalFailedShotsOffensePositional);
        res +=  (+dto.totalPostOutOffensePositional);
        res +=  (+dto.totalLostBallsOffensePositional);
    }
    if (filter == 'COUNTER_GOAL' || filter == 'all') {
        res +=  (+dto.totalGoalsCounterGoals);
        res +=  (+dto.totalFailedShotsCounterGoal);
        res +=  (+dto.totalPostOutCounterGoal);
        res +=  (+dto.totalLostBallsCounterGoals);
    }
    if (filter == 'FAST_BREAK' || filter == 'all') {
        res +=  (+dto.totalGoalsFastBreak);
        res +=  (+dto.totalFailedShotsFastBreak);
        res +=  (+dto.totalPostOutFastBreak);
        res +=  (+dto.totalLostBallsFastBreak);
    }
    if (filter == 'EQUALITY') {
        res +=  (+dto.totalGoalsEquality);
        res +=  (+dto.totalFailedShotsEquality);
        res +=  (+dto.totalPostOutEquality);
        res +=  (+dto.totalLostBallsEquality);
    }
    if (filter == 'SUPERIORITY') {
        res +=  (+dto.totalGoalsSuperiority);
        res +=  (+dto.totalFailedShotsSuperiority);
        res +=  (+dto.totalPostOutSuperiority);
        res +=  (+dto.totalLostBallsSuperiority);
    }
    return res;
};
export const avgPossessionEventsPlayer = (
    dto: PlayerStatsAggregateDto,
    filter: PhasesActionTypes|'EQUALITY'|'SUPERIORITY'|'all' = 'all'
): number => {
    let res = 0;
    if (filter == 'OFFENSE_POSITIONAL' || filter == 'all') {
        res +=  (+dto.avgGoalsOffensePositional);
        res +=  (+dto.avgFailedShotsOffensePositional);
        res +=  (+dto.avgPostOutOffensePositional);
        res +=  (+dto.avgLostBallsOffensePositional);
    }
    if (filter == 'COUNTER_GOAL' || filter == 'all') {
        res +=  (+dto.avgGoalsCounterGoals);
        res +=  (+dto.avgFailedShotsCounterGoal);
        res +=  (+dto.avgPostOutCounterGoal);
        res +=  (+dto.avgLostBallsCounterGoals);
    }
    if (filter == 'FAST_BREAK' || filter == 'all') {
        res +=  (+dto.avgGoalsFastBreak);
        res +=  (+dto.avgFailedShotsFastBreak);
        res +=  (+dto.avgPostOutFastBreak);
        res +=  (+dto.avgLostBallsFastBreak);
    }
    if (filter == 'EQUALITY') {
        res +=  (+dto.avgGoalsEquality);
        res +=  (+dto.avgFailedShotsEquality);
        res +=  (+dto.avgPostOutEquality);
        res +=  (+dto.avgLostBallsEquality);
    }
    if (filter == 'SUPERIORITY') {
        res +=  (+dto.avgGoalsSuperiority);
        res +=  (+dto.avgFailedShotsSuperiority);
        res +=  (+dto.avgPostOutSuperiority);
        res +=  (+dto.avgLostBallsSuperiority);
    }
    return res;
};

export const transformTotalTeamQuickStats = (dto: TeamStatsAggregateDto): QuickStatsColumnModel =>
    !dto ? undefined : {
        totalGoals: +dto.totalGoals,
        totalPossessions: +dto.totalPossessions,
        totalFailedShots: (+dto.totalFailedShotsFastBreak)
            + (+dto.totalFailedShotsCounterGoal)
            + (+dto.totalFailedShotsOffensePositional)
            + (+dto.totalPostOutOffensePositional)
            + (+dto.totalPostOutFastBreak)
            + (+dto.totalPostOutCounterGoal),
        equalityGoals: +dto.totalGoalsEquality,
        equalityPossessions: +dto.totalPossessionsEquality,
        superiorityGoals: +dto.totalGoalsSuperiority,
        superiorityPossessions: +dto.totalPossessionsSuperiority,
        inferiorityGoals: 0, // not needed
        inferiorityPossessions: 0, // not needed
        totalSaves: +dto.totalSaves,
        counterGoalGoals: +dto.totalGoalsCounterGoals,
        counterGoalPossession: +dto.totalPossCounterGoal,
        counterGoalEffectivePossession: +dto.totalEffectivePossCounterGoal,
        fastBreakGoals: +dto.totalGoalsFastBreak,
        fastBreakPossession: +dto.totalPossFastBreak,
        fastBreakEffectivePossession: +dto.totalEffectivePossFastBreak,
        offensePositionalGoals: +dto.totalGoalsOffensePositional,
        offensePositionalPossession: +dto.totalPossOffensePositional,
        offensePositionalEffectivePossession: +dto.totalEffectivePossOffensePositional,
        totalLostBalls: +dto.totalLostBalls,
        teamName: '', // not needed
        totalPostOuts: (+dto.totalPostOutOffensePositional)
            + (+dto.totalPostOutFastBreak)
            + (+dto.totalPostOutCounterGoal),
        totalAssist: totalAssists(dto, 'totalAssist'),
    };

export const transformAvgTeamQuickStats = (dto: TeamStatsAggregateDto): QuickStatsColumnModel =>
    !dto ? undefined : {
        totalGoals: +dto.avgGoals,
        totalPossessions: +dto.avgPossessions,
        totalFailedShots: (+dto.avgFailedShotsFastBreak)
            + (+dto.avgFailedShotsCounterGoal)
            + (+dto.avgFailedShotsOffensePositional)
            + (+dto.avgPostOutOffensePositional)
            + (+dto.avgPostOutFastBreak)
            + (+dto.avgPostOutCounterGoal),
        equalityGoals: +dto.avgGoalsEquality,
        equalityPossessions: +dto.avgPossessionsEquality,
        superiorityGoals: +dto.avgGoalsSuperiority,
        superiorityPossessions: +dto.avgPossessionsSuperiority,
        inferiorityGoals: 0, // not needed
        inferiorityPossessions: 0, // not needed
        totalSaves: +dto.avgSaves,
        counterGoalGoals: +dto.avgGoalsCounterGoals,
        counterGoalPossession: +dto.avgPossCounterGoal,
        counterGoalEffectivePossession: +dto.avgEffectivePossCounterGoal,
        fastBreakGoals: +dto.avgGoalsFastBreak,
        fastBreakPossession: +dto.avgPossFastBreak,
        fastBreakEffectivePossession: +dto.avgEffectivePossFastBreak,
        offensePositionalGoals: +dto.avgGoalsOffensePositional,
        offensePositionalPossession: +dto.avgPossOffensePositional,
        offensePositionalEffectivePossession: +dto.avgEffectivePossOffensePositional,
        totalLostBalls: +dto.avgTotalBalls,
        teamName: '', // not needed
        totalPostOuts: (+dto.avgPostOutOffensePositional)
            + (+dto.avgPostOutFastBreak)
            + (+dto.avgPostOutCounterGoal),
        totalAssist: totalAssists(dto, 'avgAssist'),
    };

export const playersTableMap = (team: PlayerStatsAggregateDto[], isBasicMode = false): PlayersTotalGameModel[] => {
    const playerTotalList = team.map((itm) => {
        const possessionEvents =
            +itm.totalGoals
            + (+itm.totalFailedShotsOffensePositional)
            + (+itm.totalFailedShotsCounterGoal)
            + (+itm.totalFailedShotsFastBreak)
            + (+itm.totalPostOut)
            + (+itm.totalLostBalls)
        ;
        const failedShoots = (+itm.totalFailedShotsCounterGoal)
            + (+itm.totalFailedShotsFastBreak)
            + (+itm.totalFailedShotsOffensePositional);

        const basicLostBallPerc = possessionEvents === 0 ? 0 : 100 * ((+itm.totalLostBalls) / possessionEvents);
        const basicEff = possessionEvents === 0 ? 0 : 100 * ((+itm.totalGoals) / possessionEvents);

        const gkSavesEff = (+itm.totalReceivedGoals) + (+itm.totalSaves) === 0
            ? 0 : 100 * ((+itm.totalSaves) / ((+itm.totalReceivedGoals) + (+itm.totalSaves)));

        const shootEff = failedShoots + (+itm.totalPostOut) === 0 ? 0
            : 100 * (+itm.totalGoals / (failedShoots + (+itm.totalGoals) + (+itm.totalPostOut)));
        return  !isBasicMode ?
            {
                playerName: `${itm.playerName}, ${itm.playerBackNumber}, ${itm.playerPosition}`,
                position: itm.playerPosition,
                playedPoses: itm.totalPossessions,
                possessionEvents: `${possessionEvents}`,
                possessionEventsAsNumber: possessionEvents,
                timePlayed: formatTimeToHoursMinutesAndSeconds(+itm.totalTime),
                timePlayedInSeconds: +itm.totalTime,
                goals: itm.totalGoals,
                goalsReceived: itm.playerPosition === 'gk' ? itm.totalReceivedGoals : 'n/a',
                assists: itm.totalAssist,
                failedShots: `${failedShoots}`,
                saves: itm.playerPosition !== 'gk' ? 'n/a' : `${+itm.totalSaves}`,
                postOuts: itm.totalPostOut,
                lostBalls: itm.totalLostBalls,
                lostBallsPerc: `${basicLostBallPerc.toFixed(1)} %`,
                eff: itm.playerPosition !== 'gk' ? `${basicEff.toFixed(1)} %` : 'n/a',
                shootEff: itm.playerPosition !== 'gk' ? `${shootEff.toFixed(1)} %`
                    : `${gkSavesEff.toFixed(1)} % GkSave`,
                playerScore: `${(+itm.totalPlayerScore).toFixed(1)}`,
                lostBallsEquality: itm.totalLostBallsEquality,
                lostBallsSuperiority: itm.totalLostBallsSuperiority,
                postOutOffensePositional: itm.totalPostOutOffensePositional,
                postOutFastBreak: itm.totalPostOutFastBreak,
                postOutCounterGoal: itm.totalPostOutCounterGoal,
                postOutEquality: itm.totalPostOutEquality,
                postOutSuperiority: itm.totalPostOutSuperiority,
                failedShotsEquality: itm.totalFailedShotsEquality,
                playerId: itm.playerId,
                playerBackNumber: itm.playerBackNumber,
                lostBallsOffensePositional: itm.totalLostBallsOffensePositional,
                lostBallsFastBreak: itm.totalLostBallsFastBreak,
                lostBallsCounterGoal: itm.totalLostBallsCounterGoals,
                attackFaultProvoke: itm.totalAttackFaultProvoke,
                attackFaultCommit: itm.totalAttackFaultCommit,
                technicalMistakeProvoke: itm.totalTechnicalMistakeProvoke,
                technicalMistakeCommit: itm.totalTechnicalMistakeCommit,
                lostBallProvoke: itm.totalLostBallProvoke,
                lostBall: itm.totalLostBall,
                twoMinCommit: itm.totalTwoMinCommit,
                twoMinProvoke: itm.totalTwoMinProvoke,
                sevenMetersCommit: itm.totalSevenMetersCommit,
                sevenMetersProvoke: itm.totalSevenMetersProvoke,
                sevenMSuspCommit: itm.totalSevenMSuspCommit,
                sevenMSuspProvoke: itm.totalSevenMSuspProvoke,
                foulCommit: itm.totalFoulCommit,
                foulReceive: itm.totalFoulReceive,
                oneAndOneLost: itm.totalOneAndOneLost,
                oneAndOneWon: itm.totalOneAndOneWon,
                block: itm.totalShotBlock,
                shotBlocked: itm.totalShotBlocked,
                efficiency: itm.avgEfficiency,
                shotEfficiency: itm.avgShotEfficiency,
                lostBallPerc: itm.avgLostBallPerc,
                goalsOffensePositional: itm.totalGoalsOffensePositional,
                goalsFastBreak: itm.totalGoalsFastBreak,
                goalsCounterGoals: itm.totalGoalsCounterGoals,
                failedShotsOffensePositional: itm.totalFailedShotsOffensePositional,
                failedShotsFastBreak: itm.totalFailedShotsFastBreak,
                failedShotsCounterGoal: itm.totalFailedShotsCounterGoal,
                totalShots: itm.totalTotalShots,
                shots7Meters: itm.totalShots7Meters,
                gkPostOut: itm.totalGkPostOut,
                gkSaves7Meters: itm.totalSaves7Meters,
                gkReceivedGoals7Meters: itm.totalGoals7Meters,
                defensePossOffensePositional: itm.totalDefensePossOffensePositional,
                defensePossFastBreak: itm.totalDefensePossFastBreak,
                defensePossCounterGoal: itm.totalDefensePossCounterGoal,
                defenseReceivedGoalsOffensePositional: itm.totalDefenseReceivedGoalsOffensePositional,
                defenseReceivedGoalsFastBreak: itm.totalDefenseReceivedGoalsFastBreak,
                defenseReceivedGoalsCounterGoal: itm.totalDefenseReceivedGoalsCounterGoal,
                playedTimeOffense: formatTimeToHoursMinutesAndSeconds(+itm.totalPlayedTimeOffense),
                playedTimeDefense: formatTimeToHoursMinutesAndSeconds(+itm.totalPlayedTimeDefense),
                playedTimeOffenseInferiority: formatTimeToHoursMinutesAndSeconds(+itm.totalPlayedTimeOffenseInferiority),
                playedTimeDefenseInferiority: formatTimeToHoursMinutesAndSeconds(+itm.totalPlayedTimeDefenseInferiority),
                playedTimeOffenseSuperiority: formatTimeToHoursMinutesAndSeconds(+itm.totalPlayedTimeOffenseSuperiority),
                playedTimeDefenseSuperiority: formatTimeToHoursMinutesAndSeconds(+itm.totalPlayedTimeDefenseSuperiority),
                playedTimeOffenseEquality: formatTimeToHoursMinutesAndSeconds(+itm.totalPlayedTimeOffenseEquality),
                playedTimeDefenseEquality: formatTimeToHoursMinutesAndSeconds(+itm.totalPlayedTimeDefenseEquality)
            } : {
                playerName: `${itm.playerName}, ${itm.playerBackNumber}, ${itm.playerPosition}`,
                possessionEvents: `${possessionEvents}`,
                possessionEventsAsNumber: possessionEvents,
                timePlayedInSeconds: 0,
                goals: itm.totalGoals,
                goalsReceived: itm.playerPosition === 'gk' ? itm.totalReceivedGoals : undefined,
                assists: itm.totalAssist,
                failedShots: `${failedShoots}`,
                saves: itm.playerPosition !== 'gk' ? 'n/a' : `${+itm.totalSaves}`,
                postOuts: itm.totalPostOut,
                lostBalls: itm.totalLostBalls,
                lostBallsPerc: `${basicLostBallPerc.toFixed(1)} %`,
                eff: itm.playerPosition !== 'gk' ? `${basicEff.toFixed(1)} %` : 'n/a',
                shootEff: itm.playerPosition !== 'gk' ? `${shootEff.toFixed(1)} %`
                    : `${gkSavesEff.toFixed(1)} % GkSave`,
                playerScore: `${(+itm.avgPlayerScore).toFixed(1)}`,
                lostBallsEquality: itm.totalLostBallsEquality,
                lostBallsSuperiority: itm.totalLostBallsSuperiority,
                postOutOffensePositional: itm.totalPostOutOffensePositional,
                postOutFastBreak: itm.totalPostOutFastBreak,
                postOutCounterGoal: itm.totalPostOutCounterGoal,
                postOutEquality: itm.totalPostOutEquality,
                postOutSuperiority: itm.totalPostOutSuperiority,
                failedShotsEquality: itm.totalFailedShotsEquality,
                playerId: itm.playerId,
                playerBackNumber: itm.playerBackNumber,
                lostBallsOffensePositional: itm.totalLostBallsOffensePositional,
                lostBallsFastBreak: itm.totalLostBallsFastBreak,
                lostBallsCounterGoal: itm.totalLostBallsCounterGoals,
                attackFaultProvoke: itm.totalAttackFaultProvoke,
                attackFaultCommit: itm.totalAttackFaultCommit,
                technicalMistakeProvoke: itm.totalTechnicalMistakeProvoke,
                technicalMistakeCommit: itm.totalTechnicalMistakeCommit,
                lostBallProvoke: itm.totalLostBallProvoke,
                lostBall: itm.totalLostBall,
                twoMinCommit: itm.totalTwoMinCommit,
                twoMinProvoke: itm.totalTwoMinProvoke,
                sevenMetersCommit: itm.totalSevenMetersCommit,
                sevenMetersProvoke: itm.totalSevenMetersProvoke,
                sevenMSuspCommit: itm.totalSevenMSuspCommit,
                sevenMSuspProvoke: itm.totalSevenMSuspProvoke,
                foulCommit: itm.totalFoulCommit,
                foulReceive: itm.totalFoulReceive,
                oneAndOneLost: itm.totalOneAndOneLost,
                oneAndOneWon: itm.totalOneAndOneWon,
                block: itm.totalShotBlock,
                shotBlocked: itm.totalShotBlocked,
                efficiency: itm.avgEfficiency,
                shotEfficiency: itm.avgShotEfficiency,
                lostBallPerc: itm.avgLostBallPerc,
                goalsOffensePositional: itm.totalGoalsOffensePositional,
                goalsFastBreak: itm.totalGoalsFastBreak,
                goalsCounterGoals: itm.totalGoalsCounterGoals,
                failedShotsOffensePositional: itm.totalFailedShotsOffensePositional,
                failedShotsFastBreak: itm.totalFailedShotsFastBreak,
                failedShotsCounterGoal: itm.totalFailedShotsCounterGoal,
                totalShots: itm.totalTotalShots,
                shots7Meters: itm.totalShots7Meters,
                gkPostOut: itm.totalGkPostOut,
                gkSaves7Meters: itm.totalSaves7Meters,
                gkReceivedGoals7Meters: itm.totalGoals7Meters,
                defensePossOffensePositional: itm.totalDefensePossOffensePositional,
                defensePossFastBreak: itm.totalDefensePossFastBreak,
                defensePossCounterGoal: itm.totalDefensePossCounterGoal,
                defenseReceivedGoalsOffensePositional: itm.totalDefenseReceivedGoalsOffensePositional,
                defenseReceivedGoalsFastBreak: itm.totalDefenseReceivedGoalsFastBreak,
                defenseReceivedGoalsCounterGoal: itm.totalDefenseReceivedGoalsCounterGoal,
                playedTimeOffense: formatTimeToHoursMinutesAndSeconds(+itm.totalPlayedTimeOffense),
                playedTimeDefense: formatTimeToHoursMinutesAndSeconds(+itm.totalPlayedTimeDefense),
                playedTimeOffenseInferiority: formatTimeToHoursMinutesAndSeconds(+itm.totalPlayedTimeOffenseInferiority),
                playedTimeDefenseInferiority: formatTimeToHoursMinutesAndSeconds(+itm.totalPlayedTimeDefenseInferiority),
                playedTimeOffenseSuperiority: formatTimeToHoursMinutesAndSeconds(+itm.totalPlayedTimeOffenseSuperiority),
                playedTimeDefenseSuperiority: formatTimeToHoursMinutesAndSeconds(+itm.totalPlayedTimeDefenseSuperiority),
                playedTimeOffenseEquality: formatTimeToHoursMinutesAndSeconds(+itm.totalPlayedTimeOffenseEquality),
                playedTimeDefenseEquality: formatTimeToHoursMinutesAndSeconds(+itm.totalPlayedTimeDefenseEquality)
            };
    });
    return !isBasicMode
        ? playerTotalList.sort((a, b) => b.timePlayedInSeconds - a.timePlayedInSeconds)
        : playerTotalList.sort((a, b) => b.possessionEventsAsNumber - a.possessionEventsAsNumber);
};

export const getPlayerColumns = async (isBasicMode = false, tg: Function) =>
    !isBasicMode ?         [
            { prop: 'playerName', name: await tg(_('Player Name')), width: 200, resizeable: false, sortable: true, frozenLeft: true },
            { prop: 'playedPoses', name: await tg(_('Played Possess')), width: 80, resizeable: false, sortable: true,  },
            { prop: 'possessionEvents', name: await tg(_('Possession Events')), width: 80, resizeable: false, sortable: true,  },
            { prop: 'timePlayed', name: await tg(_('Time Played hh:mm:ss')), width: 80, resizeable: false, sortable: true,  },
            { prop: 'goals', name: await tg(_('Goals')), width: 80, resizeable: false, sortable: true,  },
            { prop: 'assists', name: await tg(_('Assists')), width: 80, resizeable: false, sortable: true,  },
            { prop: 'failedShots', name: await tg(_('Failed Shots')), width: 80, resizeable: false, sortable: true,  },
            { prop: 'goalsReceived', name: await tg(_('Goals Received')), width: 80, resizeable: false, sortable: true,  },
            { prop: 'saves', name: await tg(_('Saves')), width: 80, resizeable: false, sortable: true,  },
            { prop: 'postOuts', name: await tg(_('Post Outs')), width: 80, resizeable: false, sortable: true,  },
            { prop: 'lostBalls', name: await tg(_('Lost Balls')), width: 80, resizeable: false, sortable: true,  },
            { prop: 'lostBallsPerc', name: await tg(_('% Lost Balls')), width: 80, resizeable: false, sortable: true,  },
            { prop: 'eff', name: await tg(_('% Eff')), width: 80, resizeable: false, sortable: true,  },
            { prop: 'shootEff', name: await tg(_('% Shoot Eff')), width: 80, resizeable: false, sortable: true,  },
            { prop: 'playerScore', name: await tg(_('Player Score')), width: 80, resizeable: false, sortable: true,  },
    ] : [
            { prop: 'playerName', name: await tg(_('Player Name')), width: 200, resizeable: false, sortable: true, frozenLeft: true },
            { prop: 'possessionEvents', name: await tg(_('Possession Events')), width: 80, resizeable: false, sortable: true,  },
            { prop: 'goals', name: await tg(_('Goals')), width: 80, resizeable: false, sortable: true,  },
            { prop: 'assists', name: await tg(_('Assists')), width: 80, resizeable: false, sortable: true,  },
            { prop: 'failedShots', name: await tg(_('Failed Shots')), width: 80, resizeable: false, sortable: true,  },
            { prop: 'goalsReceived', name: await tg(_('Goals Received')), width: 80, resizeable: false, sortable: true,  },
            { prop: 'saves', name: await tg(_('Saves')), width: 80, resizeable: false, sortable: true,  },
            { prop: 'postOuts', name: await tg(_('Post Outs')), width: 80, resizeable: false, sortable: true,  },
            { prop: 'lostBalls', name: await tg(_('Lost Balls')), width: 80, resizeable: false, sortable: true,  },
            { prop: 'lostBallsPerc', name: await tg(_('% Lost Balls')), width: 80, resizeable: false, sortable: true,  },
            { prop: 'eff', name: await tg(_('% Eff')), width: 80, resizeable: false, sortable: true,  },
            { prop: 'shootEff', name: await tg(_('% Shoot Eff')), width: 80, resizeable: false, sortable: true,  },
            { prop: 'playerScore', name: await tg(_('Player Score')), width: 80, resizeable: false, sortable: true,  },
    ];

export const getPlayerColumnsForTeams = async (isBasicMode = false, tg: Function) =>
    !isBasicMode ?         [
      { prop: 'playerName', name: await tg(_('Player Name')), width: 200, resizeable: false, sortable: true, frozenLeft: true },
      { prop: 'playedPoses', name: await tg(_('Played Possess')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'possessionEvents', name: await tg(_('Possession Events')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'timePlayed', name: await tg(_('Time Played hh:mm:ss')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'goals', name: await tg(_('Goals')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'assists', name: await tg(_('Assists')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'failedShots', name: await tg(_('Failed Shots')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'goalsReceived', name: await tg(_('Goals Received')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'saves', name: await tg(_('Saves')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'postOuts', name: await tg(_('Post Outs')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'lostBalls', name: await tg(_('Lost Balls')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'lostBallsPerc', name: await tg(_('% Lost Balls')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'eff', name: await tg(_('% Eff')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'shootEff', name: await tg(_('% Shoot Eff')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'playerScore', name: await tg(_('Player Score')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'lostBallsEquality', name: await tg(_('Lost Balls Equality')), width: 80, resizeable: false, sortable: true,  },

      { prop: 'attackFaultProvoke', name: await tg(_('Attack Fault Provoke')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'attackFaultCommit', name: await tg(_('Attack Fault Commit')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'block', name: await tg(_('Block')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'foulCommit', name: await tg(_('Foul Commit')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'foulReceive', name: await tg(_('Foul Receive')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'lostBallProvoke', name: await tg(_('Lost Ball Provoke')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'lostBallsSuperiority', name: await tg(_('Lost Balls Superiority')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'sevenMetersCommit', name: await tg(_('Seven Meters Commit')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'sevenMetersProvoke', name: await tg(_('Seven Meters Provoke')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'sevenMSuspCommit', name: await tg(_('Seven M Susp Commit')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'sevenMSuspProvoke', name: await tg(_('Seven M Susp Provoke')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'shotBlocked', name: await tg(_('Shot Blocked')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'shotEfficiency', name: await tg(_('Shot Efficiency')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'technicalMistakeProvoke', name: await tg(_('Technical Mistake Provoke')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'technicalMistakeCommit', name: await tg(_('Technical Mistake Commit')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'shots7Meters', name: await tg(_('Shots 7 Meters')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'totalShots', name: await tg(_('Total Shots')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'twoMinCommit', name: await tg(_('Two Min Commit')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'twoMinProvoke', name: await tg(_('Two Min Provoke')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'playedTimeOffense', name: await tg(_('Played Time Offense')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'playedTimeDefense', name: await tg(_('Played Time Defense')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'playedTimeOffenseInferiority', name: await tg(_('Played Time Offense Inferiority')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'playedTimeDefenseInferiority', name: await tg(_('Played Time Defense Inferiority')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'playedTimeOffenseSuperiority', name: await tg(_('Played Time Offense Superiority')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'playedTimeDefenseSuperiority', name: await tg(_('Played Time Defense Superiority')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'playedTimeOffenseEquality', name: await tg(_('Played Time Offense Equality')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'playedTimeDefenseEquality', name: await tg(_('Played Time Defense Equality')), width: 80, resizeable: false, sortable: true,  },

      //new
      { prop: 'postOutOffensePositional', name: await tg(_('Post Out Offense Positional')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'postOutFastBreak', name: await tg(_('Post Out Fast Break')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'postOutCounterGoal', name: await tg(_('Post Out Counter Goal')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'postOutEquality', name: await tg(_('Post Out Equality')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'postOutSuperiority', name: await tg(_('Post Out Superiority')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'failedShotsEquality', name: await tg(_('Failed Shots Equality')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'playerBackNumber', name: await tg(_('Player Back Number')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'lostBallsOffensePositional', name: await tg(_('Lost Balls Offense Positional')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'lostBallsFastBreak', name: await tg(_('Lost Balls Fast Break')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'lostBallsCounterGoal', name: await tg(_('Lost Balls Counter Goal')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'lostBall', name: await tg(_('Lost Ball')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'goalsFastBreak', name: await tg(_('Goals Fastbreak')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'goalsCounterGoals', name: await tg(_('Goals Countergoals')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'failedShotsOffensePositional', name: await tg(_('Failed Shots Offense Positional')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'failedShotsFastBreak', name: await tg(_('Failed Shots Fast Break')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'failedShotsCounterGoal', name: await tg(_('Failed Shots Counter Goal')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'oneAndOneLost', name: await tg(_('One And One Lost')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'oneAndOneWon', name: await tg(_('One And One Won')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'efficiency', name: await tg(_('Efficiency')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'lostBallPerc', name: await tg(_('Lost Ball Perc')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'goalsOffensePositional', name: await tg(_('Goals Offense Positional')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'gkPostOut', name: await tg(_('Gk Post Out')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'gkSaves7Meters', name: await tg(_('Gk Saves 7 Meters')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'gkReceivedGoals7Meters', name: await tg(_('Gk Received Goals 7 Meters')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'defensePossOffensePositional', name: await tg(_('Defense Poss Offense Positional')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'defensePossFastBreak', name: await tg(_('Defense Poss Fast Break')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'defensePossCounterGoal', name: await tg(_('Defense Poss Counter Goal')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'defenseReceivedGoalsOffensePositional', name: await tg(_('Defense Received Goals Offense Positional')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'defenseReceivedGoalsFastBreak', name: await tg(_('Defense Received Goals Fast Break')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'defenseReceivedGoalsCounterGoal', name: await tg(_('Defense Received Goals Counter Goal')), width: 80, resizeable: false, sortable: true,  },
    ] : [
      { prop: 'playerName', name: await tg(_('Player Name')), width: 200, resizeable: false, sortable: true, frozenLeft: true },
      { prop: 'possessionEvents', name: await tg(_('Possession Events')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'goals', name: await tg(_('Goals')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'assists', name: await tg(_('Assists')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'failedShots', name: await tg(_('Failed Shots')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'goalsReceived', name: await tg(_('Goals Received')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'saves', name: await tg(_('Saves')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'postOuts', name: await tg(_('Post Outs')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'lostBalls', name: await tg(_('Lost Balls')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'lostBallsPerc', name: await tg(_('% Lost Balls')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'eff', name: await tg(_('% Eff')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'shootEff', name: await tg(_('% Shoot Eff')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'playerScore', name: await tg(_('Player Score')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'lostBallsEquality', name: await tg(_('Lost Balls Equality')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'attackFaultProvoke', name: await tg(_('Attack Fault Provoke')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'attackFaultCommit', name: await tg(_('Attack Fault Commit')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'block', name: await tg(_('Block')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'foulReceive', name: await tg(_('Foul Receive')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'foulCommit', name: await tg(_('Foul Commit')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'lostBallProvoke', name: await tg(_('Lost Ball Provoke')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'lostBallsSuperiority', name: await tg(_('Lost Balls Superiority')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'sevenMetersCommit', name: await tg(_('Seven Meters Commit')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'sevenMetersProvoke', name: await tg(_('Seven Meters Provoke')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'sevenMSuspCommit', name: await tg(_('Seven M Susp Commit')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'sevenMSuspProvoke', name: await tg(_('Seven M Susp Provoke')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'shotBlocked', name: await tg(_('Shot Blocked')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'shotEfficiency', name: await tg(_('Shot Efficiency')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'technicalMistakeProvoke', name: await tg(_('Technical Mistake Provoke')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'technicalMistakeCommit', name: await tg(_('Technical Mistake Commit')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'shots7Meters', name: await tg(_('Shots 7 Meters')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'totalShots', name: await tg(_('Total Shots')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'twoMinCommit', name: await tg(_('Two Min Commit')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'twoMinProvoke', name: await tg(_('Two Min Provoke')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'playedTimeOffense', name: await tg(_('Played Time Offense')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'playedTimeDefense', name: await tg(_('Played Time Defense')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'playedTimeOffenseInferiority', name: await tg(_('Played Time Offense Inferiority')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'playedTimeDefenseInferiority', name: await tg(_('Played Time Defense Inferiority')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'playedTimeOffenseSuperiority', name: await tg(_('Played Time Offense Superiority')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'playedTimeDefenseSuperiority', name: await tg(_('Played Time Defense Superiority')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'playedTimeOffenseEquality', name: await tg(_('Played Time Offense Equality')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'playedTimeDefenseEquality', name: await tg(_('Played Time Defense Equality')), width: 80, resizeable: false, sortable: true,  },

      //new
      { prop: 'postOutOffensePositional', name: await tg(_('Post Out Offense Positional')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'postOutFastBreak', name: await tg(_('Post Out Fast Break')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'postOutCounterGoal', name: await tg(_('Post Out Counter Goal')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'postOutEquality', name: await tg(_('Post Out Equality')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'postOutSuperiority', name: await tg(_('Post Out Superiority')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'failedShotsEquality', name: await tg(_('Failed Shots Equality')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'playerBackNumber', name: await tg(_('Player Back Number')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'lostBallsOffensePositional', name: await tg(_('Lost Balls Offense Positional')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'lostBallsFastBreak', name: await tg(_('Lost Balls Fast Break')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'lostBallsCounterGoal', name: await tg(_('Lost Balls Counter Goal')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'lostBall', name: await tg(_('Lost Ball')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'goalsFastBreak', name: await tg(_('Goals Fastbreak')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'goalsCounterGoals', name: await tg(_('Goals Countergoals')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'failedShotsOffensePositional', name: await tg(_('Failed Shots Offense Positional')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'failedShotsFastBreak', name: await tg(_('Failed Shots Fast Break')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'failedShotsCounterGoal', name: await tg(_('Failed Shots Counter Goal')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'oneAndOneLost', name: await tg(_('One And One Lost')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'oneAndOneWon', name: await tg(_('One And One Won')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'efficiency', name: await tg(_('Efficiency')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'lostBallPerc', name: await tg(_('Lost Ball Perc')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'goalsOffensePositional', name: await tg(_('Goals Offense Positional')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'gkPostOut', name: await tg(_('Gk Post Out')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'gkSaves7Meters', name: await tg(_('Gk Saves 7 Meters')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'gkReceivedGoals7Meters', name: await tg(_('Gk Received Goals 7 Meters')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'defensePossOffensePositional', name: await tg(_('Defense Poss Offense Positional')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'defensePossFastBreak', name: await tg(_('Defense Poss Fast Break')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'defensePossCounterGoal', name: await tg(_('Defense Poss Counter Goal')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'defenseReceivedGoalsOffensePositional', name: await tg(_('Defense Received Goals Offense Positional')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'defenseReceivedGoalsFastBreak', name: await tg(_('Defense Received Goals Fast Break')), width: 80, resizeable: false, sortable: true,  },
      { prop: 'defenseReceivedGoalsCounterGoal', name: await tg(_('Defense Received Goals Counter Goal')), width: 80, resizeable: false, sortable: true,  },
    ];


export const transformPlayByPlayToViewModel = (playByPlayDtoList: PlayByPlayDto[]): GameSystemStatsViewModel[] => {
    const statsModel = new GameSystemStatsModel('EMPTY');
    playByPlayDtoList.forEach(value => {
        if (value.event === 'GOAL') {
            transformGameOpening(value.gameSystem)
                .forEach(gs => statsModel.addGoal(gs, value?.executionPosition as ExecutionPositionTypes));
        } else if (value.event === 'SAVE') {
            transformGameOpening(value.gameSystem)
                .forEach(gs => statsModel.addSave(gs, value?.executionPosition as ExecutionPositionTypes));
        } else if (value.event === 'POST_OUT') {
            transformGameOpening(value.gameSystem)
                .forEach(gs => statsModel.addPostOut(gs, value?.executionPosition as ExecutionPositionTypes));
        } else if (value.event === 'FOUL_RECEIVE') {
            transformGameOpening(value.gameSystem)
                .forEach(gs => statsModel.addFault(gs));
        } else if (['2MIN_PROVOKE','7M_PROVOKE_SUSPENSION'].includes(value.event)) {
            transformGameOpening(value.gameSystem)
                .forEach(gs => statsModel.addSuspension(gs));
            if (value.event === '7M_PROVOKE_SUSPENSION') {
              transformGameOpening(value.gameSystem)
                  .forEach(gs => statsModel.add7MetersProvokePlus2Min(gs));
            }
        } else if (['ATTACK_FAULT_COMMIT','TECHNICAL_MISTAKE_COMMIT','LOST_BALL'].includes(value.event)) {
            transformGameOpening(value.gameSystem)
                .forEach(gs => statsModel.addLostBall(gs));
        } else if (value.event === '7M_PROVOKE') {
          transformGameOpening(value.gameSystem)
              .forEach(gs => statsModel.add7MetersProvoke(gs));
        } else {}
    });
    return sortByCategoryAndNumberOfActions(statsModel.generateGameSystemStats().gameSystemStatsView);
};

export const gameSystemColumns = async (tg: Function) => [
    { prop: 'actionDisplayName', name: await tg(_('Action Name'))},
    { prop: 'categoryName', name: await tg(_('Category'))},
    { prop: 'colorCode', name: await tg(_('Saves' ))},
    { prop: 'numberOfActionsPlayed', name: await tg(_('TIMES PLAYED' ))},
    { prop: 'numberOfGoals', name: await tg(_('N° GOALS' ))},
    { prop: 'numberOfSaves', name: await tg(_('N° SAVES' ))},
    { prop: 'numberOfPostOuts', name: await tg(_('N° POST / OUT' ))},
    { prop: 'numberOfActionsPlayedWith7Meters', name: await tg(_('N° 7 METERS' ))},
    { prop: 'numberOfFault', name: await tg(_('N° FAULT' ))},
    { prop: 'numberOfSuspension', name: await tg(_('N° SUSPENSION' ))},
    { prop: 'numberOfLostBall', name: await tg(_('N° LOST BALL' ))},
    { prop: 'percentageGoals', name: await tg(_('% GOALS' ))},
    { prop: 'percentageNoGoals', name: await tg(_('% NO GOALS' ))},
];

export const gameSystemMyTeamColumns = async (tg: Function) => [
  { prop: 'actionDisplayName', name: await tg(_('Action Name'))},
  { prop: 'categoryName', name: await tg(_('Category'))},
  { prop: 'colorCode', name: await tg(_('Saves' ))},
  { prop: 'numberOfActionsPlayed', name: await tg(_('TIMES PLAYED' ))},
  { prop: 'numberOfGoals', name: await tg(_('N° GOALS' ))},
  { prop: 'numberOfSaves', name: await tg(_('N° SAVES' ))},
  { prop: 'numberOfPostOuts', name: await tg(_('N° POST / OUT' ))},
  { prop: 'sevenMetersProvoke', name: await tg(_('N° 7M PROVOKE' ))},
  { prop: 'sevenMetersProvoke2Min', name: await tg(_('N° 7M PROVOKE + 2 MIN' ))},
  { prop: 'numberOfFault', name: await tg(_('N° FAULT' ))},
  { prop: 'numberOfSuspension', name: await tg(_('N° SUSPENSION' ))},
  { prop: 'numberOfLostBall', name: await tg(_('N° LOST BALL' ))},
  { prop: 'percentageGoals', name: await tg(_('% GOALS' ))},
  { prop: 'percentageNoGoals', name: await tg(_('% NO GOALS' ))},
  { prop: 'percentageLostBalls', name: await tg(_('% LOST BALLS' ))}
];
